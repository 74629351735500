@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter-Bold.woff2') format('woff2'),
    url('../fonts/Inter-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter-Regular.woff2') format('woff2'),
    url('../fonts/Inter-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}


@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter-SemiBold.woff2') format('woff2'),
    url('../fonts/Inter-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter-Medium.woff2') format('woff2'),
    url('../fonts/Inter-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Raleway';
  src: url('../fonts/Raleway-Bold.woff2') format('woff2'),
    url('../fonts/Raleway-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'icomoon';
  src: url('../fonts/icomoon.eot?dsn16s');
  src: url('../fonts/icomoon.eot?dsn16s#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?dsn16s') format('truetype'),
    url('../fonts/icomoon.woff?dsn16s') format('woff'),
    url('../fonts/icomoon.svg?dsn16s#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}



.icon-deleted-report:before {
  content: "\e900";
}
.icon-missing-company:before {
  content: "\e901";
}
.icon-coverage-request:before {
  content: "\e902";
}
.icon-update-request:before {
  content: "\e903";
}
.icon-ETF:before {
  content: "\e904";
}
.icon-ScoringRanking:before {
  content: "\e905";
}
.icon-arrow_caret:before {
  content: "\e906";
}
.icon-merge:before {
  content: "\e907";
}
.icon-key:before {
  content: "\e908";
}
.icon-search2:before {
  content: "\e909";
}
.icon-calendar:before {
  content: "\e90a";
}
.icon-report:before {
  content: "\e90b";
}
.icon-dotmenu:before {
  content: "\e90c";
}
.icon-flag:before {
  content: "\e90d";
}
.icon-users:before {
  content: "\e90e";
}
.icon-data-search:before {
  content: "\e90f";
}
.icon-globe:before {
  content: "\e910";
}
.icon-download:before {
  content: "\e911";
}
.icon-comment:before {
  content: "\e912";
}
.icon-delete:before {
  content: "\e913";
}
.icon-plus2:before {
  content: "\e914";
}
.icon-link:before {
  content: "\e915";
}
.icon-refresh:before {
  content: "\e916";
}
.icon-minus:before {
  content: "\e917";
}
.icon-down-arrow:before {
  content: "\e918";
}
.icon-edit:before {
  content: "\e919";
}
.icon-graph:before {
  content: "\e91a";
}
.icon-home:before {
  content: "\e91b";
}
.icon-duplicate:before {
  content: "\e91c";
}
.icon-view-file:before {
  content: "\e91d";
}
.icon-caret-arrow:before {
  content: "\e91e";
}
.icon-next:before {
  content: "\e91f";
}
.icon-exclamation:before {
  content: "\e920";
}
.icon-prev:before {
  content: "\e921";
}
.icon-search:before {
  content: "\e922";
}
.icon-setting:before {
  content: "\e923";
}
.icon-union:before {
  content: "\e924";
}
.icon-up-arrow:before {
  content: "\e925";
}
.icon-user:before {
  content: "\e926";
}
.icon-check:before {
  content: "\e927";
}
.icon-plus:before {
  content: "\e928";
}
.icon-cross:before {
  content: "\e929";
}


a,
body,
div,
fieldset,
font,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
html,
img,
label,
li,
nav,
ol,
p,
section,
select,
span,
strong,
table,
tbody,
td,
th,
thead,
tr,
ul {

  outline: 0;

  border: 0;

  font-weight: inherit;

  font-style: inherit;

  margin: 0;

  padding: 0;

  box-sizing: border-box;

}


*,
*::after,
*::before {

  -webkit-box-sizing: border-box;

  -moz-box-sizing: border-box;

  box-sizing: border-box;

}

html,
body {
  height: 100%;
}

ul,
li {
  list-style: none;
}

body {
  padding: 0;
  margin: 0;
  font-family: 'Inter';
  font-weight: 400;
  background-color: #F8F8F7;
  overflow-x: hidden;
}

#header {
  padding: 9px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #FFF;
  margin-bottom: 15px;
  position: relative;
  z-index: 2;
  box-shadow: 0px 2px 24px 1px rgba(225, 225, 225, 0.80);
}

.logo {
  display: flex;
  align-items: center;
  gap: 10px;
}

.logo_icon img {
  height: 34px;
}

.brand_name {
  font-weight: bold;
  font-size: 24px;
  color: #0B223A;
}

.brand_name span {
  font-weight: 500;
  color: #878787;
}

.header_monthly_value {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.three_year_price {
  border-radius: 4px;
  background: #E1E1E1;
}

.month_price_row {
  display: flex;
  align-items: center;
  background-color: #E1E1E1;
  border-radius: 4px;
}

.month_col {
  /* width: 100px; */
  padding: 0 14px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #E1E1E1;
  font-size: 14px;
  font-weight: bold;
  color: #2B3779;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.month_col span {
  font-weight: 400;
}

.price_white_col {
  border-radius: 4px;
  background: #141B3C;
  color: #FFFFFF;
  font-size: 14px;
  height: 30px;
  /* width: 290px; */
  padding-left: 12px;
  padding-right: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  gap: 4px;
}

.price_white_col span,
.price_byellow_col span {
  font-weight: bold;
}

.price_byellow_col {
  background-color: #FEEED0;
  color: #0B223A;
  font-size: 14px;
  height: 30px;
  padding-left: 12px;
  padding-right: 12px;
  /* width: 290px; */
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  gap: 4px;
}

.three_year_price .divider,
.price_byellow_col .divider {
  font-size: 13px;
  font-weight: 300;
  color: #C3C3C3;
  vertical-align: middle;
  display: block;
  margin-top: 1px;
}

.value_sortname {
  color: #8D99DB;
  font-size: 12px;
  font-style: normal;
  font-weight: 600 !important;
}

.value_sortname strong {
  font-weight: bold !important;
}

.content {
  padding-left: 30px;
  padding-right: 30px;
}

.table {
  margin-bottom: 0;
}

.report_table {
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  /* box-shadow: 0px 2px 24px 1px rgba(225, 225, 225, 0.80), 0px 2px 24px 1px rgba(225, 225, 225, 0.80), 0px 103px 29px 0px rgba(168, 168, 168, 0.00), 0px 66px 26px 0px rgba(168, 168, 168, 0.01), 0px 37px 22px 0px rgba(168, 168, 168, 0.05), 0px 17px 17px 0px rgba(168, 168, 168, 0.09), 0px 4px 9px 0px rgba(168, 168, 168, 0.10); */
}

.report_table tbody tr:nth-child(odd) td {
  background-color: #F2F5FC
}

.report_table tbody tr:nth-child(even) td {
  background-color: #FFF
}

.table>thead {
  vertical-align: middle;
  position: relative;
  z-index: 1;
}

.financial_table .table>thead.clsTHead {
  z-index: 1;
}

.financial_table .table>thead.clsSubTHead {
  z-index: 0;
}

.report_table thead th,
.report_table td {
  padding: 6px 12px;
  text-align: center;
  border: 0;
  vertical-align: middle;
  /* white-space: nowrap; */
}

.report_table td {
  font-weight: 600;
  color: #000;
  font-size: 14px;
}

.report_table thead th {
  background-color: #E1E1E1;
  color: #141B3C;
  font-size: 16px;
  font-family: 'Raleway';
  text-align: center;
  position: sticky;
  top: 0;
}

.report_table thead th.standard_th {
  text-align: left;
  color: #FFF;
  font-size: 20px;
  background-color: #141B3C;
  width: 220px;
  cursor: pointer;
}

.report_table td:first-child {
  width: 220px;
}

.report_table td.standard_td,
.report_table td.left_align_td {
  text-align: left;
}

.threshold_row {
  background-color: #EBEBE8;
}

.threshold_row td {
  color: #0B223A;
  background-color: #EBEBE8 !important;
}

.table_icon_cross i {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: #FFB1B1;
  font-size: 9px;
  color: #F04D56;
}

.report_table .fa-remove:before,
.report_table .fa-close:before,
.report_table .fa-times:before {
  margin-bottom: 2px;
}

.table_icon_cross,
.table_icon_exlamation,
.table_icon_check {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.table_icon_exlamation i {
  display: inline-block;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  line-height: 18px;
  background-color: #F5C980;
  text-align: center;
  font-size: 10px;
  vertical-align: middle;
  color: #54414B;
}

.table_icon_check i {
  display: inline-block;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  line-height: 19px;
  background-color: #A8E0A0;
  text-align: center;
  font-size: 9px;
  vertical-align: middle;
  color: #0A8F43;
}

.report_table td.tble_green_color {
  color: #028C07;
  background-color: #EAF9E8 !important;
}

.report_table td.tble_red_color {
  color: #BD000B;
  background-color: #FFE0E0 !important;
  /* text-transform: uppercase; */
  font-weight: bold;
  letter-spacing: 1px;
}

.report_table td.left_align_td {
  border-right: 1px solid #D7E1F7;
}

.report_table td.tble_yellow_color {
  color: #B37C00;
  background-color: #FFF9E4 !important;
  font-weight: bold;
  letter-spacing: 1px;
}

.report_table td.tble_lyellow_color {
  background: #FFF9E4 !important;
  color: #BA9100 !important;
}

.css-1jqq78o-placeholder {
  font-size: 14px;
  color: #000 !important;
}


.report_table .table-responsive {
  max-height: 70vh;
}

.report_table td.epagging_col {
  padding-top: 8px;
  padding-bottom: 8px;
}

::-webkit-scrollbar {
  width: 6px;
  height: 12px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
}

::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #ccc;
}


#filter_row {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.filter_col {
  display: flex;
  align-items: center;
  gap: 10px;
}

.searchfield {
  display: flex;
  align-items: center;
  position: relative;
  /* border-radius: 4px;
  border: 1.4px solid #D7E1F7;
  background: #F2F5FC; */
  border-radius: 4px;
  border: 1.4px solid #D7E1F7;
  background: #F2F5FC;
  height: 36px;
  padding-left: 30px;
  max-width: 200px;
  width: 100%;
}

.filter_searchfield,
.filter_searchfield:focus {
  border: 0;
  background: none;
  border-radius: 4px;
  height: 34px;
  width: 100%;
  outline: none;
  font-size: 15px;
}

.btn_search {
  position: absolute;
  left: 5px;
  background: none;
  border: none;
  color: #5A7F97;
  font-size: 16px;
}

::-webkit-input-placeholder {
  color: #878787;
}

::-moz-placeholder {
  color: #878787;
}

:-ms-input-placeholder {
  color: #878787;
}

:-moz-placeholder {
  color: #878787;
}

#filter_row ::placeholder {
  font-weight: 500;
}


.searchfield ::-webkit-input-placeholder {
  color: #BCCDF2;
}

.searchfield ::-moz-placeholder {
  color: #BCCDF2;
}

.searchfield :-ms-input-placeholder {
  color: #BCCDF2;
}

.searchfield :-moz-placeholder {
  color: #BCCDF2
}

.btn_search{
  color:#BCCDF2;
}

.url_col_filter {
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  border: 1px solid #E1E1E1;
  background: #FFF;
  height: 40px;
  padding-left: 12px;
  max-width: 160px;
}

.url_col_filter span {
  font-size: 14px;
  font-weight: 600;
}

.url_field,
.url_field:focus {
  border: 1px solid #BBC2E6;
  background: #F2F5FC;
  border-radius: 4px;
  border: 1px solid #E1E1E1;
  background: #FFF9EF;
  height: 40px;
  padding-left: 12px;
  outline: none;
  width: 100%;
}

.date_col_filter {
  border-radius: 4px;
  border: 1px solid #E1E1E1;
  background: #FFF;
  display: flex;
  height: 40px;
  padding-left: 9px;
  align-items: center;
  gap: 7px;
}

.date_col_filter span {
  font-size: 13px;
  color: #0E1228;
  font-weight: 600;
}

.date_col_text {
  border-radius: 4px;
  background: #F8F8F7;
  height: 38px;
  color: #000;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  display: flex;
  align-items: center;
  padding: 0 14px;
}

.currency_dropdown_col_filter {
  border-radius: 4px;
  border: 1px solid #E1E1E1;
  background: #F8F8F7;
  height: 40px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  position: relative;
  z-index: 2;
}

.currency_dropdown_col_filter select {
  background-color: #F8F8F7;
  font-weight: 500;
  font-size: 13px;
  height: 38px;
  color: #000;
}

.currency_dropdown_col_filter .css-b62m3t-container {
  width: 100px;
}

.css-15lsz6c-indicatorContainer {
  padding: 0 !important;
}

.currency_col_filter {
  border-radius: 4px;
  border: 1px solid #E1E1E1;
  background: #F8F8F7;
  font-weight: 500;
  font-size: 13px;
  color: #000;
  height: 40px;
  display: flex;
  align-items: center;
  padding: 0px 10px;
  position: relative;
  z-index: 2;
}

.financial_table {
  border-radius: 8.176px;
  border: 1.022px solid #D7E1F7;
  overflow: hidden;
  /* padding-bottom: 20px; */
}

.financial_table .table-responsive {
  max-height: 98vh;
}

.financial_table td {
  font-size: 14px;
  border-top: 0px solid #D7E1F7
}

.financial_table thead th {
  /* border-radius: 8.176px 8.176px 0px 0px; */
  background: #141B3C;
  color: #FFF;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  border: 0;
  position: sticky;
  top: 0;
  padding: 3px 0px;
}

.financial_table thead td {
  background: #787FA0;
  color: #FFF;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  border: 0;
}

.financial_table td {
  padding: 3px 5px;
  vertical-align: middle;
  /*width: 65%;*/
}

/* .financial_table td:first-child{
  padding-left: 40px;
} */

/* .financial_table td:nth-child(2) {
  min-width: 180px;
} */

.financial_table td input.borderNone{
  min-width: 180px;
}

.financial_table td.price_right_align {
  background-color: #E0F9D8;
  text-align: right;
}

.text_right {
  text-align: right;
}

.financial_table td.tbl_green_col {
  background-color: #E0F9D8;
}

.financial_table td:last-child {
  padding-left: 0;
  width: 62px;
}

.cash_items_row td {
  background-color: #D7E1F7;
  font-size: 16px;
  font-weight: 600;

}

.cash_items_row td:first-child {
  padding-left: 5px;

}

.tbl_manual_row td {
  background-color: #FFF9EF;
  font-size: 16px;
  font-weight: 600;
}

.tbl_total_assets_row td {
  background-color: #C3C3C3;
  font-size: 16px;
  font-weight: bold;
  color: #141B3C;
}

.tbl_total_assets_row td:first-child {
  padding-left: 15px;
}

.tbl_current_row td {
  background: #787FA0;
  color: #FFF;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  border: 0;
}

.tbl_bas_row td {
  /* border-radius: 8.176px 8.176px 0px 0px; */
  background: #141B3C;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  color: #FFF;

}

.tbl_manual_row input {
  text-align: right;
  border: 0;
  background: none;
  outline: none;
  font-weight: 600;
  display: block;
  width: 100%;
}

.select_box {
  display: flex;
  justify-content: flex-end;
  z-index: 0;
  align-items: center;
  height: 24px;
}

.select_box select {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
  outline: none;
  width: 40px;
}

.select_box_red_caret select {
  background: url(../images/caret-red.svg) right center no-repeat;
  background-size: 16px;
}

.select_box_green_caret select {
  background: url(../images/caret-green.svg) right center no-repeat;
  background-size: 16px;
}

.select_box_yellow_caret .css-1xc3v61-indicatorContainer {
  background: url(../images/caret-yellow.svg) right center no-repeat;
  background-size: 16px;
}

.select_box_green_caret .css-1xc3v61-indicatorContainer {
  background: url(../images/caret-green.svg) right center no-repeat;
  background-size: 16px;
}

.select_box_red_caret .css-1xc3v61-indicatorContainer {
  background: url(../images/caret-red.svg) right center no-repeat;
  background-size: 16px;
}

.select_box .css-1xc3v61-indicatorContainer,
.select_box .css-15lsz6c-indicatorContainer {
  width: 16px;
  height: 16px;
}

.select_box .css-1dimb5e-singleValue svg {
  width: 20px;
  height: 20px;
}

/* .select_box .css-tj5bde-Svg{
  display: none;
} */

.select_box select::-ms-expand {
  display: none;
}

.select_box .css-b62m3t-container {
  width: 50px;
}

.select_box select {
  color: #028C07;
  font-size: 18px;
}

.table tr td .click_accordion {
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
  transition: all 0.5s ease;
}

.table tr .click_accordion:before {
  display: inline-block;
  content: "";
  background: url(../images/minus.svg) left center no-repeat;
  background-size: contain;
  width: 18px;
  height: 18px;
  transition: all 0.5s ease;
  /* position: absolute; */
}

.table tr.collapsed .click_accordion:before {
  display: inline-block;
  content: "";
  background: url(../images/plus.svg) left center no-repeat;
  background-size: contain;
  width: 18px;
  height: 18px;
  transition: all 0.5s ease;
  /* position: absolute; */
}

.collapsed_td {
  background: #F2F5F8;
}

.tbl_accordion_row td {
  font-weight: 600;
  font-size: 16px;
  color: #000;
}

.tbl_accordion_row td:first-child {
  padding-left: 15px;
}

.css-13cymwt-control {
  border: 0 !important;
  background-color: transparent !important;
  box-shadow: none !important;
}

.css-t3ipsp-control,
.css-t3ipsp-control:hover {
  border: 0 !important;
  background-color: transparent !important;
  box-shadow: none !important;
}

.css-1u9des2-indicatorSeparator {
  background-color: transparent;
  width: 0 !important;
}

.css-1dimb5e-singleValue {
  font-size: 14px;
  font-weight: 500;
  color: #000 !important;
}

.css-qbdosj-Input {
  padding: 0 !important;
}

.css-1xc3v61-indicatorContainer,
.css-1fdsijx-ValueContainer {
  padding: 0 !important;
}

.css-tj5bde-Svg {
  fill: #696969;
  width: 15px !important;
  height: 15px !important;
}

.css-1nmdiq5-menu {
  display: block !important;
  visibility: visible !important;
  opacity: 1 !important;
}

/* .select_box .css-8mmkcg{
  display: none !important;
} */

.pro-sidebar .overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 100;
  display: none;
}

.pro-sidebar {
  color: #adadad;
  background-color: #141B3C;
  height: 100%;
  width: 260px;
  min-width: 260px;
  text-align: left;
  transition: width, left, right, 0.3s;
  position: fixed;
  left: 0;
  z-index: 1009;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  overflow: hidden;
}

.pro-sidebar>.pro-sidebar-inner {
  background-color: transparent;
}

.css-1l8icbj {
  padding-left: 0;
}

.pro-item-content,
.css-ahj2mt-MuiTypography-root {
  font-family: 'Inter' !important;
  font-weight: 500 !important;
}

.pro-item-content a,
.css-ahj2mt-MuiTypography-root {
  font-family: 'Inter' !important;
  font-weight: 500 !important;
  color: #F6F8FA !important;
  font-size: 15px !important;
  line-height: 16px;
  /* 133.333% */
  letter-spacing: -0.24px !important;
}

.MuiTypography-h6 {
  text-transform: uppercase;
  font-size: 11px !important;
  letter-spacing: 0.4px !important;
  color: #F6F8FA !important;
  font-family: 'Inter' !important;
  white-space: nowrap;
}

.pro-sidebar.collapsed {
  width: 0px;
  min-width: 0px;
  left: -260px;
}

#sidebarTrigger {
  margin: 0;
  width: 30px;
  height: 85px;
  position: fixed;
  left: -13px;
  top: 66px;
  z-index: 4;
  border-radius: 0px 8px 8px 0px;
  background: #141B3C;
}

.pro-sidebar-inner>ul {
  padding-left: 12px !important;
  padding-right: 12px !important;
}

.pro-sidebar .pro-menu .pro-menu-item.active,
.pro-sidebar .pro-menu .pro-menu-item.active a,
.pro-sidebar .pro-menu .pro-menu-item.active .MuiTypography-root {
  background-color: #FFF9EF;
  border-radius: 8px;
  color: #000 !important;
}

.pro-sidebar .pro-menu .pro-menu-item.open>.pro-inner-item {
  background-color: #FFF9EF !important;
  border-radius: 8px;
  color: #000 !important;
}


.pro-sidebar .pro-menu .pro-menu-item.open>.pro-inner-item .pro-item-content {
  color: #000 !important;
}

.pro-sidebar .pro-menu .pro-menu-item>.pro-inner-item {
  padding: 0px 12px;
  height: 40px;
}

.react-slidedown .pro-inner-item {
  height: 36px !important;
}

.pro-sidebar .pro-menu .pro-menu-item {
  position: relative;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 8px;
  margin-bottom: 8px
}

.menu_setting_section {
  padding-top: 12px;
}

.pro-sidebar .pro-menu .pro-menu-item:before {
  position: absolute;
  left: 0;
  top: 9px;
}

.react-slidedown {
  padding-left: 0px !important;
}

.react-slidedown .pro-menu-item {
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item .react-slidedown .pro-inner-item {
  padding: 8px 0px 8px 15px !important;
}

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item {
  margin-top: 14px;
}


.css-1e2t9cj-MuiTypography-root {
  margin: 15px 0 5px 30px !important;
}

.pro-item-content,
.css-ahj2mt-MuiTypography-root {
  color: #FFF;
}

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu>.pro-inner-item>.pro-arrow-wrapper {
  bottom: inherit !important;
  top: inherit !important;
  transform: inherit !important;
  position: static !important;
}

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu>.pro-inner-item>.pro-arrow-wrapper .pro-arrow {
  transform: rotate(45deg) !important;
}

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu.open>.pro-inner-item>.pro-arrow-wrapper .pro-arrow {
  transform: rotate(-133deg) !important;
}

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu.open>.pro-inner-item>.pro-arrow-wrapper .pro-arrow {
  border-color: #222222;
}

.react-slidedown .pro-menu-item .pro-item-content {
  font-size: 13px !important;
}

.pro-menu-item.active .pro-inner-item .pro-item-content {
  color: #000 !important;
}

.pro-sidebar .react-slidedown {
  padding-left: 10px !important;
  margin-left: 15px !important;
}

.pro-item-content i {
  margin-right: 6px;
  font-size: 19px;
  vertical-align: text-bottom;
  width: 20px;
  display: inline-block;
}

.pro-item-content i.icon-ETF,
.pro-item-content i.icon-ScoringRanking{
  font-size: 20px;
}

.react-slidedown {
  border-left: 2px solid #E1E1E1;
}

.report_table_data_row .col-xl-7 {
  padding-right: 3px;
}

.report_table_data_row .col-xl-5 {
  padding-left: 3px;
}

.btn_add_new {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none !important;
  gap: 8px;
  border-radius: 31.142px;
  background: #E0E4FA;
  color: #141B3C;
  font-size: 15px;
  font-weight: 500;
  padding: 0px 20px;
  height: 36px;
}

.btn_add_new i {
  color: #2B3779;
  font-size: 13px;
}

.btn_clear_filter {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  border-radius: 4px;
  background: #F2F5FC;
  color: #4B5DBC;
  font-size: 14px;
  font-weight: 400;
  width: 118px;
  padding: 0px 16px;
  height: 36px;
  white-space: nowrap;
}

.btn_download_xl {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  text-decoration: none;
  border-radius: 4px;
  background: #E0E4FA;
  color: #141B3C;
  font-size: 16px;
  font-weight: 500;
  padding: 0px 16px;
  height: 36px;
  white-space: nowrap;
}

.btn_download_xl i {
  font-size: 20px;
}

.company_table_list {
  border-radius: 16px;
  background: #FFF;
  box-shadow: 0px 2px 24px 1px rgba(225, 225, 225, 0.80), 0px 2px 24px 1px rgba(225, 225, 225, 0.80);
  overflow: hidden;
  position: relative;
}

.company_table_list .table-responsive {
  max-height: 90vh;
  padding-bottom: 100px;
}

.company_table_list th {
  padding: 15px 15px;
}

.company_table_list td {
  padding: 5px 15px;
  font-size: 14px;
  font-weight: 500;
  color: #2D2D2D;
  vertical-align: middle;
  white-space: nowrap;
}



.company_table_list th {
  border-bottom: 1px solid #D7E1F7;
  background: #FFF9EF;
  color: #00203D;
  font-family: 'Raleway';
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.6px;
  vertical-align: middle;
  white-space: nowrap;
  position: sticky;
  top: 0;
}

.company_table_list tr:nth-child(even) td {
  background-color: #F2F5FC;
}

.company_name_td {
  display: flex;
  align-items: center;
  gap: 10px;
}

.company_lable {
  border-radius: 4px;
  background: #D7E1F7;
  color: #2D2D2D;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  width: 93px;
  height: 25px;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.stock_lable {
  border-radius: 4px;
  background: #D7E1F7;
  color: #2D2D2D;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  width: 110px;
  height: 30px;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.stock_lable2 {
  border-radius: 4px;
  background: #D7E1F7;
  color: #2D2D2D;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  width: 125px;
  height: 30px;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.tag_td {
  border-radius: 24px;
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  /* 150% */
  letter-spacing: 1px;
  text-transform: uppercase;
  display: flex;
  width: 130px;
  height: 28px;
  justify-content: center;
  align-items: center;
}

.uncover_tag {
  background: #E1E1E1;
  color: #2D2D2D;
}

.not_halal_tag {
  background: #FFE0E0;
  color: #BD000B;
}

.data_error_tag {
  background: #BD000B;
  color: #FFE0E0;
}

.halal_tag {
  background: #EAF9E8;
  color: #0E5A29;
}

.doughtful_tag {
  background: #FFF4CC;
  color: #C79005;
}

.table_center .tag_td2 {
  margin: auto;
}

.tag_td2 {
  border-radius: 24px;
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  /* 150% */
  letter-spacing: 1px;
  text-transform: uppercase;
  display: flex;
  width: 140px;
  height: 28px;
  justify-content: center;
  align-items: center;
}

.not_covered_tag {
  background: #FFE0E0;
  color: #BD000B;
}

.covered_tag {
  background: #EAF9E8;
  color: #0E5A29;
}

.status_label {
  font-weight: bold;
  font-size: 16px;
}

.status_pending {
  color: #E0A510;
}

.status_completed {
  color: #0A8F43;
}

.table_center .btn_completed {
  margin: auto;
}

.btn_completed {
  background-color: #FEEED0;
  color: #141B3C;
  font-size: 13px;
  font-weight: 600;
  border-radius: 8px;
  border: 0;
  outline: none;
  width: 140px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn_completed.disabled {
  background-color: #F5F5F5;
  color: #878787;
  font-size: 13px;
  font-weight: 600;
  border-radius: 8px;
  border: 1px solid #E1E1E1;
  outline: none;
  width: 140px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#pagination_row {
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  padding: 20px 12px;
  background: linear-gradient(0deg, #FFF 0%, #FFF 0%, rgba(255, 255, 255, 0.90) 0.01%, rgba(255, 255, 255, 0.75) 50%, rgba(255, 255, 255, 0.20) 100%, rgba(255, 255, 255, 0.35) 100%);
}

.pagination {
  border-radius: 30px;
  border: 1.4px solid #BCCDF2;
  background: #FFF;
  padding: 6.905px;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.clsPagination .pagination {
  padding: 1px;
}


.pagination .page-link {
  color: #878787;
  text-align: center;
  font-size: 15px;
  font-style: normal;
  border: 0;
  background: none;
  font-weight: 800;
  line-height: normal;
  border-radius: 60px;
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}

.pagination .extra_Pagination_gap .page-link {
width: 52px;
height: 52px;
}
.active>.page-link,
.page-link.active {
  color: #2B3779;
  background-color: #D7E1F7;
}

.page-item:first-child .page-link,
.page-item:last-child .page-link {
  border: 0;
  background: none;
  color: #6776C6;
}

.page-link:focus {
  box-shadow: none;
}

.action_row {
  display: flex;
  align-items: center;
  gap: 5px;
}

.action_row a {
  display: block;
  text-decoration: none;
}

.btn_create_report {
  border-radius: 8px;
  background: #FEEED0;
  color: #141B3C;
  font-size: 14px;
  font-weight: 600;
  padding: 6px 15px;
}

.btn_icon_action {
  font-size: 28px;
  text-decoration: none !important;
}

.header_table_filter {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  /* width: 58%; */
}

.dropdown_select {
  border-radius: 4px;
  background: #F5F5F5;
  border: 1px solid #E1E1E1;
  height: 36px;
  /* padding: 0 15px; */
}

.dropdown__select {
  border-radius: 4px;
  background: #F5F5F5;
  border: 1px solid #E1E1E1;
  height: 36px;
}

.dropdown_select .css-13cymwt-control {
  min-height: 34px !important;
}

.dropdown_select .css-t3ipsp-control {
  min-height: 34px !important;
}

.dropdown_col_filter {
  max-width: 220px;
  width: 100%;
}

.dropdown_select .css-1jqq78o-placeholder {
  color: #878787 !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.status_active {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  font-size: 11px;
  border-radius: 100px;
  background: #A8E0A0;
  color: #0A8F43;
  margin: auto;
}

.status_unpublish {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  font-size: 11px;
  border-radius: 100px;
  background: #FFE0E0;
  color: #BD000B;
  margin: auto;
}

.table_td_big_content_url {
  position: relative;
  cursor: pointer;
}

.text_ellipsis {
  width: 140px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.copied_text {
  position: absolute;
  top: 1px;
  font-size: 13px;
  font-weight: 500;
  color: #0A8F43;
  z-index: 99;
  line-height: 1;
  background: #FFF;
  padding: 4px;
}

.login_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: url(../images/bg-login.jpg) center center no-repeat;
  background-size: cover;
  min-height: 100vh;
}

.login_content {
  max-width: 480px;
  width: 100%;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 16px;
  background: #FFF;
  /* box-shadow: 45px 323px 91px 0px rgba(253, 204, 113, 0.00), 29px 207px 83px 0px rgba(253, 204, 113, 0.01), 16px 116px 70px 0px rgba(253, 204, 113, 0.05), 7px 52px 52px 0px rgba(253, 204, 113, 0.09), 2px 13px 29px 0px rgba(253, 204, 113, 0.10); */
}

.login_header {
  background: url(../images/pattern-login-head.svg) center center no-repeat #FEEED0;
  background-size: cover;
  padding-top: 16px;
  padding-bottom: 16px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  position: relative;
}


.login_header svg{

  position: absolute;

  left: 0;

  top: 0;

  width: 100%;

  height: 100%;
}

.login_logo {
  /* padding-left: 20px; */
  text-align: center;
}

.login_header_text {
  text-align: center;
  /* padding-right: 30px; */
}

.login_header_text h3 {
  color: #2B3779;
  font-weight: bold;
  font-size: 30px;
  margin-top: 8px;
}

.login_header_text p {
  color: #696969;
  font-weight: 500;
  font-size: 17px;
  margin-top: 6px;
  /* letter-spacing: -0.24px; */
  text-transform: capitalize;
}

.login_header_text h6 {
  color: #7d6944;
  font-weight: 600;
  font-size: 12px;
  text-transform: uppercase;
  /* letter-spacing: -0.16px; */
  margin-top: 3px
}

.login_form {
  padding: 30px 45px;
  padding-top: 0px;
}

.field_row .form-control {
  border-radius: 8px;
  border: 1px solid #C3C3C3;
  background: #FFF;
  box-shadow: none;
  height: 44px;
  padding-left: 18px;
  padding-right: 18px;
}

.field_row {
  margin-top: 20px;
}

.custom_label {
  margin-bottom: 4px;
  color: #696969;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  /* line-height: 32px; */
}

.text_field {
  position: relative;
}

.icon_field {
  position: absolute;
  right: 18px;
  top: 0;
  bottom: 0;
  margin: auto;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.icon_field img {
  width: 20px;
}

.icon_field svg {
  width: 24px;
  height: 20px;
}

.btn_login {
  border-radius: 8px;
  background: #141B3C;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFF;
  text-align: center;
  font-size: 19px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  width: 100%;
  border: 0;
  box-shadow: none;
}

.login_logo img {
  height: 60px;
}


.loader {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 0;
  height: 100vh;
  transition: width 0s 1.4s ease;
}

.loader .loader__icon {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  opacity: 0;
  transition: opacity 0.5s ease;
  margin-top: -40px;
  margin-left: -40px;
}

.loader .loader__icon svg {
  transform-origin: 0 0;
}

.loader .loader__tile {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  background-color: #293364;
  transform: scale(0);
  transition: transform 0.3s ease;
}

.loader--active {
  width: 100%;
  transition-delay: 0s;
}

.loader--active .loader__icon {
  opacity: 1;
  transition: opacity 0.5s 1.4s ease;
}

.loader--active .loader__tile {
  transform: scale(1.2);
  transition: transform 0.3s ease;
}

.report_table thead th {
  padding: 6px 15px;

}

@media(min-width:1599px) {

  .report_table thead th,
  .report_table td {
    padding: 6px 15px;

  }

  .report_table td {
    font-size: 16px;
  }

  .report_table thead th {
    font-size: 18px;
    padding: 6px 15px;
  }

  .report_table thead th.standard_th {
    font-size: 24px;
  }

  .table_icon_cross i {
    width: 20px;
    height: 20px;
    font-size: 10px;
  }

  .fa-remove:before,
  .fa-close:before,
  .fa-times:before {
    margin-bottom: 2px;
  }

  .table_icon_cross,
  .table_icon_exlamation,
  .table_icon_check {
    gap: 6px;
  }

  .table_icon_exlamation i {
    display: inline-block;
    width: 20px;
    height: 20px;
    line-height: 20px;
    font-size: 9px;
  }

  .table_icon_check i {
    display: inline-block;
    width: 20px;
    height: 20px;
    line-height: 21px;
    font-size: 10px;
  }

  .filter_col {
    gap: 10px;
  }

  .url_col_filter {
    gap: 10px;
    height: 40px;
    padding-left: 9px;
  }

  .url_col_filter span {
    font-size: 14px;
  }

  .url_field,
  .url_field:focus {
    height: 40px;
    padding-left: 9px;
  }

  .date_col_text {
    font-size: 14px;
  }

  .currency_dropdown_col_filter select {
    font-size: 14px;
  }

  .currency_col_filter {
    font-size: 14px;
  }

}

.table_center {
  text-align: center;
}

.table_center .company_lable2 {
  margin: auto;
}

.company_lable2 {
  width: 145px;
  height: 32px;
  color: #2D2D2D;
  font-weight: 600;
  font-size: 13px;
  background-color: #D7E1F7;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#header_screening {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #FFF;
  margin-bottom: 15px;
}

#header_screening .logo {
  width: 70px;
  justify-content: center;
}

.header_right_section {
  border-left: 1px solid #DEE5ED;
  width: calc(100% - 70px);
}

.header_right_section .header_monthly_value {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.header_section_top {
  border-bottom: 1px solid #DEE5ED;
  padding-bottom: 8px;
  padding-top: 8px;
  padding-left: 30px;
  padding-right: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header_section_bottom {
  padding-bottom: 8px;
  padding-top: 8px;
  padding-left: 30px;
  padding-right: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header_control_links {
  display: flex;
  align-items: center;
  gap: 16px;
}

.currency_col_filter {
  display: flex;
  align-items: center;
  gap: 10px;
}

.btn_currency_refresh {
  background: none;
  border: 0;
  outline: none;
  color: #878787;
}

.currency_col_filter .css-b62m3t-container {
  width: 80px;
}

.Screening_sidebar #sidebarTrigger {
  top: 110px;
}

.timer {
  width: 140px;
  height: 42px;
  background-color: #BCCDF2;
  color: #141B3C;
  font-weight: 600;
  font-size: 18px;
  gap: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}

.link_trigger {
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #2B3779;
  font-size: 24px;
  background-color: #FFF9EF;
  text-decoration: none !important;
  border-radius: 10px;
}

.toggle_switch {
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FFF9EF;
  border-radius: 10px;
}

.switch input {
  position: absolute;
  opacity: 0;
}

.switch {
  display: inline-block;
  font-size: 17px;
  /* 1 */
  height: 1em;
  width: 36px;
  background: #2B3779;
  border-radius: 1em;
}

.switch div {
  height: 11px;
  width: 11px;
  border-radius: 1em;
  margin-left: 2.5px;
  margin-top: 3.4px;
  /* background: #FFF; */
  background: #FDCC72;
  border: 1px solid #FFF;
  /* box-shadow: 0 0.1em 0.3em rgba(0,0,0,0.3); */
  -webkit-transition: all 300ms;
  -moz-transition: all 300ms;
  transition: all 300ms;
}

.switch input:checked+div {
  -webkit-transform: translate3d(178%, 0, 0);
  -moz-transform: translate3d(178%, 0, 0);
  transform: translate3d(178%, 0, 0);
}

/* NEW SWITCH */
.switchSmall {
  display: inline-block;
}

.switchSmall input {
  display: none;
}

.switchSmall small {
  display: inline-block;
  width: 32px;
  height: 16px;
  background: #C3C3C3;
  border-radius: 30px;
  position: relative;
  cursor: pointer;
}

.switchSmall small:before {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  background: #fff;
  border-radius: 50%;
  top: 3px;
  left: 3px;
  transition: .3s;
  box-shadow: -3px 0 3px rgba(0, 0, 0, 0.1);
}

.switchSmall input:checked~small {
  background: #6B91E3;
  transition: .3s;
}

.switchSmall input:checked~small:before {
  transform: translate(16px, 0px);
  transition: .3s;
}


.report_table_data_row {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 10px;
}

.screening_report_pdf {
  width: 50%;
}

.financial_table_col {
  width: 50%;
}

.btn_slide_trigger {
  border: 0;
  transition: transform 0.5s ease;
  /* z-index: 9; */
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FFF9EF;
  border-radius: 10px;
  /* margin-top: 15px; */
}

.btn_slide_trigger img {
  width: 25px;
}

.screening_report_pdf,
.financial_table_col {
  transition: transform 0.5s ease;
  transform: translateX(0%);
}

.iframe_pdf {
  border-radius: 10px;
  height: calc(89vh + 65px);
}


#Reportbox1 {
  order: 1;
  width: 55%;
  margin-top: 20px;
}

#Reportbox2 {
  order: 2;
  width: 45%;
  margin-top: 20px;
}


.slide-in {
  transform: translateX(82.8%);
  order: 1;
  /* Slide in to the left */
  transition: transform 0.5s ease;
}

.slide-out {
  transform: translateX(-124%);
  order: 2;
  /* Slide out to the left */
  transition: transform 0.5s ease;
}

.setting_table_content .table-responsive {
  padding-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
}

.setting_table_content thead th {
  top: -16px;
  color: #2D2D2D;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-bottom: 0;
}

.setting_table_content table {
  border: 1px solid #F5F5F5;
}

.setting_page_table_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  border-bottom: 1px solid #E0E4FA;
}

.page_subtitle {
  font-size: 20px;
  color: #2D2D2D;
  font-weight: 600;
}

.setting_table_filter_row {
  display: flex;
  align-items: center;
  gap: 5px;
}

.setting_table_filter_row .searchfield {
  width: 210px;
  position: relative;
  border-radius: 4px;
  border: 1.4px solid #D7E1F7;
  background: #F2F5FC;
}

.setting_table_filter_row .btn_search {
  left: 6px;
}

.setting_table_filter_row .searchfield .filter_searchfield::-webkit-input-placeholder {
  color: #BCCDF2;
}

.setting_table_filter_row .searchfield .filter_searchfield::-moz-placeholder {
  color: #BCCDF2;
}

.setting_table_filter_row .searchfield .filter_searchfield:-ms-input-placeholder {
  color: #BCCDF2;
}

.setting_table_filter_row .searchfield .filter_searchfield:-moz-placeholder {
  color: #BCCDF2;
}

.setting_table_filter_row .dropdown_select {
  width: 235px;
  position: relative;
  z-index: 2;
  background-color: #F5F5F5;
  border: 1px solid #E1E1E1;
  border-radius: 4px;
}

/* SETTINGS NAVIGATION */
.settign_table_list {
  overflow: inherit;
}

.toggle__input {
  position: absolute;
  width: 1px;
  height: 1px;
  opacity: 0;
}

.toggle__label {
  position: absolute;
  z-index: 200;
  cursor: pointer;
  left: -13px;
  top: 14px;
  transition: 0.4s all;
}

.toggle__label span {
  position: relative;
  display: block;
  width: 32px;
  height: 32px;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  border-top: 4px solid #333;
  border-bottom: 4px solid #333;
  transition: 0.4s ease;
}

.toggle__label span:before,
.toggle__label span:after {
  content: "";
  position: absolute;
  top: calc(50% - 2px);
  display: block;
  width: 100%;
  height: 4px;
  background: #333;
  transition: 0.4s ease;
}

.setting_nav {
  position: absolute;
  z-index: 2;
  width: 240px;
  height: 100%;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 20px;
  overflow-y: auto;
  background: #fefefe;
  transform: translateX(-300px);
  /* opacity: 0; */
  transition: transform 0.4s ease;
  border-top-left-radius: 16px;

}

.nav__list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.nav__item {
  /* border-bottom: 1px solid #e5e5e5; */
}

.nav__item a {
  display: block;
  /* height: 48px; */
  /* line-height: 48px; */
  padding: 12px 16px;
  color: #333;
  text-decoration: none;
  /* text-transform: uppercase; */
  border-radius: 8px;
}

.setting_table_content {
  position: relative;
  width: 100%;
  min-height: 100%;
  transition: all 0.4s;
  transform: translateX(0px);
  width: calc(100% - 0px);
}

.toggle__input:checked+.toggle__label {
  left: 224px;
  transition: 0.4s all;
}

.toggle__input:checked+.toggle__label span {
  border-color: transparent;
}

.toggle__input:checked+.toggle__label span:before {
  transform: rotate(-45deg);
}

.toggle__input:checked+.toggle__label span:after {
  transform: rotate(45deg);
}

.toggle__input:checked~.setting_nav {
  border-right: 1px solid #E0E4FA;
  transform: translateX(0);
  opacity: 1;
  transition: transform 0.4s ease;
}

.toggle__input:checked~.o-mask {
  width: 100%;
  height: 100%;
  opacity: 0.7;
  transition: opacity 0.4s ease;
}

.toggle__input:checked~.setting_table_content {
  transform: translateX(240px);
  width: calc(100% - 240px);
}

/* END SETTINGS NAVIGATION */

.paste_link_box {
  border-radius: 8px;
  background: #FFF;
  box-shadow: 0px 2px 24px 1px rgba(105, 105, 105, 0.25);
  overflow: hidden;
  width: 410px;
  position: absolute;
  z-index: 2;
  min-height: 100px;
  opacity: 0;
  transform: translateY(-20px);
  visibility: hidden;
  transition: 300ms;
}

.paste_link_box.active {
  opacity: 1;
  transform: translateY(10px);
  visibility: visible;
}

.paste_link_header {
  color: #141B3C;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 12px 20px;
  background: #D7E1F7;
}

.paste_link_body {
  padding: 20px;
  padding-top: 0;
  padding-bottom: 25px;
}

.link_field_row {
  margin-top: 20px;
}

.paste_link_box label {
  color: #696969;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 4px;
}

.paste_link_box label i {
  color: #696969;
  font-size: 19px;
  margin-right: 4px;
}

.paste_link_box .form-control {
  border-radius: 4px;
  border: 1px solid #BCCDF2;
  background: #F2F5FC;
  height: 36px;
  padding-left: 12px;
  padding-right: 12px;
}

.submenu {
  /* display: none; */
  max-height: 0;
  transition: max-height 0.3s ease-in-out;
  overflow: hidden;
  border-left: 2px solid #D7E1F7;
  margin-left: 25px;
  padding-left: 10px;
}

.submenu.open {
  /* display: block; */
  max-height: 500px;
}

.submenu li a {
  color: #696969;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}

.submenu li a:hover {
  background: #FFF9EF;
  color: #2D2D2D
}

.setting_nav .nav__item a.active {
  background: #FFF9EF;
  color: #2D2D2D
}

.nav__item.parent .parent_link {
  position: relative;
  display: flex;
  align-items: center;
  gap: 12px;
  color: #2D2D2D;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  padding-right: 20px;
}

.nav__item.parent .parent_link i {
  font-size: 24px;
}

.nav__item.parent .parent_link:after {
  position: relative;
  content: "\e912";
  display: block;
  font-family: 'icomoon';
  position: absolute;
  right: 10px;
  top: 14px;
  font-size: 13px;
}

.table_number_field {
  width: 90px;
  border-radius: 4px;
  border: 1.4px solid #E1E1E1;
  background: #F8F8F7;
  height: 28px;
  outline: none;
  padding-left: 10px;
  padding-right: 10px;
}

.savebtn {
  border-radius: 4px;
  background: #FEEED0;
  width: 90px;
  height: 28px;
  text-align: center;
  color: #141B3C;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  outline: none;
}

.rs-input-group {
  background: none !important;
  border: 0 !important
}

.calendar_col {
  padding: 0;
}

.rs-input,
.rs-input:focus {
  font-size: 13px;
  color: #0E1228;
  font-weight: 500;
  background: none;
  box-shadow: none;
  outline: none !important;
}

.rs-input-group:not(.rs-input-group-disabled).rs-input-group-focus,
.rs-input-group:focus-within {
  outline: none !important;
}

.rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
  color: #fff;
  color: var(--rs-calendar-date-selected-text);
  background-color: #36406f !important;
  background-color: #36406f !important;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
}

.rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
  box-shadow: inset 0 0 0 1px #36406f !important;
}

.rs-picker-popup .rs-calendar .rs-calendar-table-cell:hover .rs-calendar-table-cell-content {
  color: #36406f !important;
}

.rs-calendar-table-cell-selected .rs-calendar-table-cell-content:hover span {
  color: #FFF !important;
}

.currency_col_filter .react-autosuggest__input {
  border: 0;
  width: 100%;
  font-family: 'Inter';
  font-size: 14px;
  background: none;
  padding-left: 0;
}

.react-autosuggest__suggestions-container--open {
  top: 40px;
  border-radius: 4px;
  border: 1px solid #e9e9e9;
}

.currency_col_filter .react-autosuggest__suggestions-container--open {
  width: 200px;
  right: 0px;
}

.currency_col_filter .select-currency {
  width: 50px;
}

.inputContainer {
  display: flex;
  align-items: center;
  line-height: 20px;
}

.btn_timer_control {
  border: 0;
  background: none;
  padding: 0;
}

.btn_timer_control img {
  vertical-align: sub;
}

.datepick {
  display: flex;
  align-items: center;
  position: relative;
  border-radius: 4px;
  border: 1px solid #E1E1E1;
  background: #F5F5F5;
  height: 36px;
  padding-left: 5px;
  /* max-width: 200px;
  width: 100%; */
}

.filter_by_country {
  max-width: 180px !important;
}

.rs-picker-toggle-wrapper {
  width: 100%;
}

.dropdown_style {
  max-width: 220px;
  /* width: 100%; */
  border-radius: 4px;
  background: #F5F5F5;
  border: 1px solid #E1E1E1;
  height: 36px;
  padding: 0;
}

.rs-picker-default .rs-btn,
.rs-picker-input .rs-btn,
.rs-picker-default .rs-picker-toggle,
.rs-picker-input .rs-picker-toggle {
  background: none !important;
  border: 0 !important
}

.rs-picker-select-menu-item:hover,
.rs-picker-select-menu-item:focus,
.rs-picker-select-menu-item.rs-picker-select-menu-item-focus {
  color: #FFF !important;
  background-color: #343c60 !important;
}

.rs-picker-select-menu-items {
  max-height: 250px !important;
}

.rs-picker-popup {
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}

.rs-picker-popup .rs-picker-list-item {
  border-bottom: 1px solid #f3f3f3;
  font-size: 14px;
}

.rs-picker-has-value .rs-btn .rs-picker-toggle-value,
.rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
  color: #0E1228 !important;
  font-weight: 500;
  font-size: 13px;
}

.header_flag_list {
  display: flex;
  align-items: center;
  gap: 8px;
}

.header_flag_list li {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #F2F5FC;
  font-size: 14px;
  border-radius: 100px;
}

.orange_flag {
  color: #F5B922;
}

.red_flag {
  color: #DA293E;
}

.green_flag {
  color: #0A8F43;
}

.task_row {
  display: flex;
  gap: 12px;
}

.task_col {
  border-radius: 10px;
  border-top: 4px #FDCC72;
  background: #FFF;
  box-shadow: 0px 2px 24px 1px rgba(225, 225, 225, 0.80);
  width: calc(25% - 9px);
  padding: 12px;
  position: relative;
  /* overflow: hidden; */
}

.task_report_col {
  border-top: 4px solid var(--Action-Grey-400, #C3C3C3);
}

.task_progress_col {
  border-top: 4px solid var(--Action-Grey-400, #FDCC72);
}

.task_reviewing_col {
  border-top: 4px solid var(--Action-Grey-400, #86A5E8);
}

.task_published_col {
  border-top: 4px solid var(--Action-Grey-400, #0A8F43);
}

#pagination_row2 {
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  padding: 20px 12px;
  padding-top: 10px;
  z-index: 5;
  background: #FFF;
}

.clsPagination {
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  padding: 20px 12px;
  padding-top: 10px;
  z-index: 5;
  background: linear-gradient(0deg, #FFF 0%, #FFF 0%, rgba(255, 255, 255, 0.90) 0.01%, rgba(255, 255, 255, 0.75) 50%, rgba(255, 255, 255, 0.20) 100%, rgba(255, 255, 255, 0.35) 100%);
}

.task_col_inner {
  max-height: 80vh;
  overflow-y: auto;
  padding-bottom: 50px;
}

.task_col_inner::-webkit-scrollbar {
  display: none;
}

.task_col_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 20px;
  position: relative;
}

.task_col_header h4 {
  color: #000;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.task_col_header h4 span {
  color: #6776C6;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.btn_task_search {
  background: none;
  border: 0;
  outline: none;
  font-size: 18px;
  color: #000000;
}

.project_task_row {
  padding: 16px;
  border-radius: 8px;
  margin-bottom: 15px;
}

.project_task_report {
  background: #F5F5F5
}

.project_task_progress {
  background: #FFF9EF;
}

.project_task_reviewing {
  background: #F2F5FC;
}

.project_task_published {
  background: #EAF9E8;
}


.project_task_head_row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ticker_task {
  display: flex;
  padding: 0px 6px;
  align-items: center;
  justify-content: center;
  height: 25px;
  gap: 8px;
  border-radius: 4px;
  background: var(--Faint-Navy-200, #D7E1F7);
  color: #2D2D2D;
  text-align: center;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.ticker_locate_row {
  display: flex;
  /* gap: 8px; */
  border-radius: 4px;
  background-color: #FFF;
}

.ticker_locate {
  display: flex;
  padding: 0px 6px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  height: 25px;
  background: var(--Action-Grey-100, #FFF);
  color: var(--Action-Grey-700, #4B4B4B);
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.ticker_country{
  display: flex;
  padding: 0px 6px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  height: 25px;
  background: #FFF;
  color: #2659C9;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.task_nav {
  display: flex;
  align-items: center;
  gap: 8px;
}

.task_nav li {
  font-size: 13px;
}

.task_nav li i {
  vertical-align: middle;
}

.task_nav li a {
  text-decoration: none;
  color: #2D2D2D;
}

.task_number {
  color: var(--Faint-Navy-700, #517DDE);
  font-size: 14px;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
}

.project_task_row h5 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--Action-Grey-800, #2D2D2D);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 12px;
}

.task_nav .rs-dropdown-toggle-caret {
  display: none !important;
}

.task_nav .rs-dropdown-toggle,
.task_nav .rs-dropdown-toggle.rs-btn {
  padding: 0;
  gap: 0;
}

.task_nav .rs-dropdown-toggle i {
  color: #2D2D2D;
}

.task_nav .rs-dropdown-item{
  border-bottom: 1px dashed #E1E1E1;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: center;
}

.task_nav .rs-dropdown-item .rs-text{
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}

.task_nav .rs-dropdown-menu {
  min-width: 85px;
  text-align: center;
}

.task_nav .rs-dropdown-item:last-child {
  border-bottom: 0;
  ;
}

.task_nav .rs-dropdown-item.task_asign_link .rs-text{
  color: #0A772D;
}

.task_nav .rs-dropdown-item.task_delete_link .rs-text {
  color: #BD000B;
}

.task_delete_link{
  position: relative;
}

.task_date_row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 12px;
}


.task_date_row p {
  color: var(--Action-Grey-600, #696969);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-transform: capitalize;
  /* 142.857% */
}

.task_date {
  color: var(--Dark-Navy-500, #4B5DBC);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

/* ANIMATED SEARCH BAR */
.btn_task_search {
  position: absolute;
  right: 3px;
  transition: all .5s;
  z-index: 4;
}

.btn_task_search:hover {
  cursor: pointer;
}

.btn_task_search::before {
  content: "";
  position: absolute;
  margin: auto;
  top: 9px;
  right: 0;
  bottom: 0;
  left: 9px;
  width: 6px;
  height: 2px;
  background: #2D2D2D;
  transform: rotate(45deg);
  transition: all 0.4s;
}

.btn_task_search::after {
  content: "";
  position: absolute;
  margin: auto;
  top: 0px;
  right: 0;
  bottom: 0;
  left: -3px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 2px solid #2D2D2D;
  transition: all 0.4s;
}

.task_col_header input {
  position: absolute;
  margin: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 40px;
  outline: none;
  border: none;
  font-size: 14px;
  border-radius: 4px;
  border: 1.4px solid var(--Action-Grey-300, #E1E1E1);
  background: var(--Action-Grey-100, #FFF);
  color: #2D2D2D;
  padding: 0 16px;
  transition: all 1s;
  opacity: 0;
  z-index: 5;
  font-weight: 500;
}

.task_col_header input:hover {
  cursor: pointer;
}

.task_col_header input:focus {
  width: 100%;
  opacity: 1;
  cursor: text;
}

.task_col_header input:focus~.btn_task_search {
  right: 12px;
  z-index: 6;
}

.task_col_header input:focus~.btn_task_search::before {
  top: 0;
  left: 0;
  width: 14px;
}

.task_col_header input:focus~.btn_task_search::after {
  top: 0;
  left: 0;
  width: 14px;
  height: 2px;
  border: none;
  background: #2D2D2D;
  border-radius: 0%;
  transform: rotate(-45deg);
}

/* END ANIMATED SEARCH */

.task_nav .rs-dropdown-open.rs-dropdown-placement-bottom-start>.rs-dropdown-menu,
.task_nav .rs-dropdown-open.rs-dropdown-placement-bottom-end>.rs-dropdown-menu {
  right: 0;
  left: inherit;
}

#pagination_row2 .pagination {
  padding: 3px;
}

#pagination_row2 .pagination .page-link {
  width: 36px;
  height: 36px;
  font-size: 14px;
  text-decoration: none;
}

.task_date_row2 {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-top: 10px;
}

.viewer_analyst_list {
  display: flex;
  flex-direction: column;
  gap: 3px;
  color: var(--Action-Grey-600, #696969);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.viewer_analyst_list li {
  position: relative;
  padding-left: 14px;
}

.viewer_analyst_list li.viewer_name:before,
.viewer_analyst_list li.analyst_name:before {
  position: absolute;
  left: 0;
  top: 5px;
}

.viewer_analyst_list li.viewer_name:before {
  content: "";
  display: block;
  height: 10px;
  width: 10px;
  border-radius: 100px;
  background-color: #0A8F43;
}

.viewer_analyst_list li.analyst_name:before {
  content: "";
  display: block;
  height: 10px;
  width: 10px;
  border-radius: 100px;
  background-color: #878787;
}

.task_tag {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
  border-radius: 100px;
  height: 24px;
  width: 100px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.task_tag_not_halal {
  color: #DA293E;
  background-color: #FECDCD;
}

.task_tag_halal {
  color: #0E5A29;
  background-color: #A8E0A0;
}

.task_tag_doubtful {
  color: #C79005;
  background-color: #FFEBA2;
}

.etf_name {
  color: #517DDE;
  font-weight: 600;
  text-decoration: none;
}

.table_dropdown {
  background-color: #FFF;
  border-radius: 4px;
  border: 1px solid var(--Action-Grey-300, #E1E1E1);
  position: relative;
  z-index: 0;
}

.form-check-input {
  width: 20px;
  height: 20px;
  border-radius: 4px !important;
  border: 1px solid var(--Faint-Navy-700, #517DDE);
  margin-top: 0;
}

.form-check-input:checked {
  background-color: #517DDE;
  border-color: #517DDE;
}

.multiselect_drop .rs-dropdown-toggle,
.multiselect_drop .rs-dropdown-toggle.rs-btn {
  background: none;
  border: 0;
}

.multiselect_drop i {
  font-size: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  border-radius: 4px;
  border: 1px solid var(--Faint-Navy-700, #517DDE);
  background: var(--Faint-Navy-700, #517DDE);
  color: #FFF;
}

.multiselect_drop .rs-dropdown-toggle-caret {
  top: 12px;
}

.add_more_btn {
  background-color: white;
  border-radius: 4px;
  width: 20px;
  height: 20px;
  border: 0;
  font-size: 12px;
  padding: 0;
  margin-right: 5px;

}

.add_more_btn i {
  vertical-align: middle;
  color: #141B3C;

}

.remove_Comment {
  display: flex;
  align-items: center;
  gap: 6px;
}

.remove_Comment li a {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #F2F5FC;
  border: 0.4px solid #BCCDF2;
  display: flex;
  align-items: center;
  justify-content: center;
}

.remove_Comment li a i.icon-delete {
  color: #DA293E;
}

.remove_Comment li a i.icon-comment {
  color: #696969;
}

.remove_Comment li a i.icon-plus {
  color: #141B3C;
  font-size: 11px;
}

.list_HoverControl {
  opacity: 0;
  transition: all 0.4s;
}

.financial_table tr:hover .list_HoverControl {
  opacity: 1;
  display: flex;
}

.duplicate_form_modal .modal-header {
  justify-content: center;
  text-align: center;
  background-color: #F2F5FC;
  padding: 8px;
  border-bottom: 0;
}

.modal-dialog {
  max-width: 560px;
}

.duplicate_form_modal .modal-header h1 {
  color: #141B3C;
  font-weight: bold;
  font-size: 14px !important;
  text-transform: uppercase;
}

.duplicate_form_content {
  padding-top: 25px;
  padding-bottom: 20px;
}

.duplicate_form_row {
  display: flex;
  gap: 12px;
}

.duplicate_form_col {
  width: 50%;
}

.modal-content {
  border-radius: 8px;
  background: #FFF;
  box-shadow: 0px 2px 24px 1px rgba(105, 105, 105, 0.25);
}

.duplicate_form_col .form-control,
.duplicate_form_col .select_drop_picker {
  height: 40px;
  border-radius: 4px;
  border: 0;
  background-color: #F8F8F7;
  border-bottom: 2px solid #FDCC72;
}



.duplicate_form_col .form-control::-webkit-input-placeholder,
.form_details_group .form-control::-webkit-input-placeholder,
.form_datepicker ::-webkit-input-placeholder {
  color: #C3C3C3;
  font-size: 14px;
}

.duplicate_form_col .form-control::-moz-placeholder,
.form_details_group .form-control::-moz-placeholder,
.form_datepicker ::-moz-placeholder {
  color: #C3C3C3;
  font-size: 14px;
}

.duplicate_form_col .form-control:-ms-input-placeholder,
.form_details_group .form-control:-ms-input-placeholder,
.form_datepicker :-ms-input-placeholder {
  color: #C3C3C3;
  font-size: 14px;
}

.duplicate_form_col .form-control:-moz-placeholder,
.form_details_group .form-control:-moz-placeholder,
.form_datepicker :-moz-placeholder {
  color: #C3C3C3;
  font-size: 14px;
}

.duplicate_form_col .rs-picker-toggle .rs-picker-toggle-placeholder,
.form_details_group .rs-picker-toggle .rs-picker-toggle-placeholder {
  color: #C3C3C3;
}

.duplicate_form_col label {
  color: #2D2D2D;
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 4px;
}

.rs-picker-popup {
  z-index: 9999;
}

.duplicate_form_content .btn__group {
  padding-top: 40px;
}

.btn__group {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
}

.btn_cancel {
  border-radius: 4px;
  border: 1px solid #FDC152;
  background: #FFF;
  display: flex;
  justify-content: center;
  width: 134px;
  height: 44px;
  padding: 3px 8px;
  align-items: center;
  flex-shrink: 0;
  color: #141B3C;
  font-weight: 500;
  font-size: 16px;
}

.btn_duplicate {
  border-radius: 4px;
  border: 1px solid var(--Faint-Navy-400, #A1B9ED);
  background: var(--Faint-Navy-200, #D7E1F7);
  display: flex;
  justify-content: center;
  width: 134px;
  height: 44px;
  padding: 3px 8px;
  align-items: center;
  flex-shrink: 0;
  color: #141B3C;
  font-weight: 600;
  font-size: 16px;
}

.btn_add_new_report {
  width: 160px;
}

.add_company_btn_list {
  display: flex;
  align-items: center;
  gap: 10px;
}

.add_company_btn_list li a {
  display: flex;
  padding: 7px 28px 8px 27px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  color: #141B3C;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 8px;
  text-decoration: none;
}

.add_company_btn_list li a.btn_unpublished {
  background: #FFE0E0;
}

.add_company_btn_list li a.btn_published {
  background: #CDF3C6;
}

.add_company_btn_list li a.btn_disabled {
  display: flex;
  padding: 7px 25px 8px 24px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  background: var(--Action-Grey-300, #E1E1E1);
}

.form_details_group {
  border-radius: 8px;
  background: #FFF;
  padding: 20px;
}

.label_form {
  font-size: 14px;
  font-weight: 600;
  color: #2D2D2D;
  margin-bottom: 3px;
}

.form_details_group .form-control {
  min-height: 36px;
  border-radius: 4px;
  border: 0;
  background-color: #F8F8F7;
  border-bottom: 2px solid #FEEED0;
  font-size: 14px;
  font-weight: 500;
}

.form_details_group input.form-control {
  height: 36px;
}

.form_datepicker {
  min-height: 36px;
  border-radius: 4px;
  border: 0;
  background-color: #F8F8F7;
  border-bottom: 2px solid #FEEED0;
  font-size: 14px;
  font-weight: 500;
}

.form_select_drop {
  min-height: 36px;
  border-radius: 4px;
  border: 0;
  background-color: #F8F8F7;
  border-bottom: 2px solid #FEEED0;
  font-size: 14px;
  font-weight: 500;
  width: 100%;
}

.company_details_form {
  border-radius: 16px;
  box-shadow: 0px 2px 24px 1px rgba(225, 225, 225, 0.80), 0px 2px 24px 1px rgba(225, 225, 225, 0.80);
  background: #F2F5FC;
  padding: 20px;
}

.company_details_form h2 {
  font-size: 25px;
  color: #2B3779;
  font-weight: bold;
}

.star_red {
  color: #BD000B;
}

.input-group-text {
  height: 36px;
  border: 0;
  border-radius: 4px;
  background-color: #EBEBE8;
  border-bottom: 2px solid #FEEED0;
  padding: 0;
}

.file_input_group .form-control {
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
}

.btn_browse,
.btn_browse:hover,
.btn_browse:focus {
  border: 0;
  outline: none;
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  border-radius: 0px 4px 4px 0px;
  background: var(--Faint-Navy-200, #D7E1F7);
}

textarea {
  resize: none;
}

.custom_row {
  display: flex;
  /* flex-wrap: wrap; */
  gap: 25px;
}

.custom_col5 {
  width: 20%;
}

.add_new_btn_group {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  border-radius: 0px 0px 16px 16px;
  background: var(--Bright-Yellow-100, #FFF9EF);
  box-shadow: 0px -2px 24px 1px rgba(225, 225, 225, 0.80);
  padding: 32px 12px;
}

.btn_cancel_form {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 160px;
  height: 48px;
  border: 2px solid #FDCC72;
  border-radius: 8px;
  color: #141B3C;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  background-color: #FFF9EF;
}

.btn_save_form {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 160px;
  height: 48px;
  color: #141B3C !important;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-radius: 8px;
  border: 1px solid var(--Bright-Yellow-500, #FDCC72);
  background: var(--Bright-Yellow-500, #FDCC72) !important;
}

.borderNone {
  background: none;
  border: 0;
  display: block;
  outline: none;
  width: 100%;
  border-radius: 4px;
  border: 0.4px solid var(--Action-Grey-300, #ebe6e6);
  height: 29px;
  padding: 0 10px;
  line-height: 29px;
  text-align: right;
}

.financial_table input {
  background: none;
  border: 0;
  display: block;
  outline: none;
  width: 100%;
  border-radius: 4px;
  border: 0.4px solid var(--Action-Grey-300, #ebe6e6);
  height: 29px;
  padding: 0 10px;
  line-height: 29px;
}

.tablel_field {
  width: 100%;
}

#etf_toggle_row {
  border-radius: 8px;
  box-shadow: 0px 2px 24px 1px rgba(225, 225, 225, 0.80);
  background-color: #FFF;
  position: relative;
  margin-bottom: 24px;
}

.etf_head_row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 15px;
}

.etf_head_row ul {
  display: flex;
  align-items: center;
  gap: 10px;
}

.etf_right_controls {
  display: flex;
  align-items: center;
  gap: 8px;
}

.input__group {
  display: flex;
  align-items: center;
  gap: 8px;
  height: 36px;
  border-radius: 4px;
  border: 1px solid var(--Action-Blue-300, #E6EEFF);
  background: var(--Action-Grey-100, #FFF);
  overflow: hidden;
}

.input__group_label {
  color: #000;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  padding: 0 10px;
  line-height: 20px;
}

.input__group_value {
  color: #0E1228;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  border-radius: 4px;
  background: #E6EEFF;
  height: 36px;
  display: flex;
  align-items: center;
  line-height: 20px;
  padding: 0 10px;
  gap: 4px;
}

.css_radio {
  width: 16px;
  height: 16px;
  background-color: #FFF;
  border: 4px solid #5991FF;
  border-radius: 50%;
  display: inline-block;
}

.btn_export_excel {
  display: flex;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #BFD4FF;
  background: #F2F5FC;
  gap: 5px;
  color: var(--Dark-Navy-800, #141B3C);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  padding: 0 10px;
  height: 36px;
  line-height: 20px;
  /* 142.857% */
}

.btn_etf_calculate {
  display: flex;
  align-items: center;
  border-radius: 4px;
  border: 1px solid var(--Bright-Yellow-200, #FEEED0);
  background: var(--Bright-Yellow-100, #FFF9EF);
  height: 36px;
  color: var(--Dark-Navy-800, #141B3C);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  /* 142.857% */
  padding: 0 10px;
}

.etf_content_row_outer {
  border-top: 1.4px solid var(--Action-Grey-200, #F5F5F5);
  /* max-width: 100vw; */
  overflow-x: scroll;
  position: relative;
  max-height: 0;
  transition: all 0.3s;
}

.etf_content_row_outer.open {
  max-height: 1000px;
  /* Adjust as needed */
}


.etf_content_row_outer::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #F5F5F5;
  display: none;
}

.etf_content_row_outer::-webkit-scrollbar {
  display: none;
  width: 12px;
  background-color: #F5F5F5;
}

.etf_content_row_outer::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: #888;
}

.etf_content_row {
  display: flex;
  gap: 10px;
  width: calc(100vw + 60px);
  padding: 20px 14px;
}

.fieldset_col {
  border: 1px solid #BFD4FF;
  padding: 10px;
  border-radius: 10px;
  position: relative;
  flex-shrink: 0;
}

.legend_title {
  background-color: #FFF;
  padding: 3px 5px;
  color: #517DDE;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  /* 114.286% */
  letter-spacing: 0.4px;
  position: absolute;
  top: -13px;
  left: 10px;
  width: fit-content;
}

.input__group_redis {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  height: 36px;
  border-radius: 4px;
  border: 1px solid var(--Action-Blue-300, #E6EEFF);
  background: var(--Action-Grey-100, #FFF);
  overflow: hidden;
}

.fieldset_col .input__group_redis {
  margin-top: 4px;
}

.input__group_redis_label {
  color: #000;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  padding: 0 10px;
  line-height: 20px;
}

.input__group_redis_value {
  color: #0E1228;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  border-radius: 4px;
  background: #FFF9EF;
  height: 36px;
  display: flex;
  align-items: center;
  line-height: 20px;
  padding: 0 10px;
  gap: 4px;
}

.three_rows_fieldset .input__group_redis {
  height: 24px;
  margin-top: 3px;
}

.three_rows_fieldset .input__group_redis_value {
  height: 24px;
}

.three_rows_fieldset .input__group_redis_value span {
  color: #696969;
}

.btn_eft_toggle {
  border: 0;
  background: none;
  outline: none;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  display: block;
  z-index: 1;
  bottom: -24px;
}

.table_dropdown2 {
  border-radius: 4px;
  border: 0.4px solid var(--Action-Grey-300, #E1E1E1);
  background: #F5F5F5;
  height: 36px;
  padding: 0 !important;
  position: relative;
  z-index: 0
}

.hiddenRow {
  padding: 0 !important;
  /* display: none; */
}

.table_accordion_row {
  display: flex;
  gap: 15px;
  padding: 15px;
}

.etf_holding_table .table tr.table_tr_odd td {
  background-color: #F6F8FA !important;
}

.etf_holding_table .table tr.table_tr_even td {
  background-color: #FFF !important;
}

.etf_holding_table .table .accordion-toggle.table_tr_odd td {
  border-bottom: 1px solid #E6EEFF;
  background-color: #D7E1F7 !important;
}

.etf_holding_table .table .accordion-toggle.collapsed.table_tr_odd td {
  border-bottom: 0px solid #E6EEFF;
  background-color: #F6F8FA !important;
}

.etf_holding_table .table .accordion-toggle.collapsed.table_tr_even td {
  border-bottom: 0px solid #E6EEFF;
  background-color: #FFF !important;
}

.etf_holding_table .table .accordion-toggle.table_tr_even td {
  border-bottom: 1px solid #E6EEFF;
  background-color: #D7E1F7 !important;
}

.etf_holding_table .accordian-body.collapse.show {
  background-color: #D7E1F7;
}

.etf_holding_table .table .accordion-toggle.table_tr_odd:hover td,
.etf_holding_table .table .accordion-toggle.table_tr_even:hover td {
  background-color: #D7E1F7 !important;
  transition: all 0.3s;
}

.table_accordion_col {
  /* width: 50%; */
  border-radius: 8px;
  background: #FFF;
  padding: 12px 15px;
}

.etf_details_row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 35px;
}

.etf_fieldset_row {
  display: flex;
  gap: 15px;
  margin-top: 18px;
}

.input__group__row {
  display: flex;
  gap: 10px;
}

.etf_details_row h4 {
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.table_row_active.active td {
  background-color: #D7E1F7 !important;
  border-bottom: 1px solid #FFF;
}

.cba_pass {
  color: #0A772D;
  font-weight: 600;
}

.cba_Fail {
  color: #BD000B;
  font-weight: 600;
}






.cls_subCategory {
  padding-left: 50px !important;
}

.cash_items_row td:nth-child(2) {
  padding-right: 15px;
}

.tbl_manual_row td:nth-child(1) {
  padding-left: 40px;
}

.borderhide input {
  border: none;
}

.borderhide {
  border: none;
  font-weight: 600;
}

.toggle__value {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.toggle__value .dark_color {
  color: #000000;
  transition: all 0.4s;
}

.toggle__value .light_color {
  color: #C3C3C3;
  transition: all 0.4s;
}

.toggle__value .switchSmall input:checked~small {
  background: #3377FF;
}

.toggle__value .switchSmall {
  margin-top: 4px;
}

/* .financial_table .table tr:nth-child(odd) td{
  background-color: #F5F5F5;
} */

.caret_green {
  color: #0A8F43;
}

.caret_red {
  color: #BD000B;
}

.caret_orange {
  color: #FCB633;
}

.caret_grey {
  color: #dbdbdb;
}

.clsTab .rs-nav-horizontal {
  border: 1px solid var(--Action-Blue-300, #b2cbff);
  height: 38px;
  border-radius: 8px;
  /* border-top-right-radius: 8px; */
  background: var(--Action-Blue-100, #F7FAFF);
  /* box-shadow: 0px 2px 24px 1px rgba(225, 225, 225, 0.80); */
  width: fit-content;
  overflow: hidden;
}

.clsTab .rs-tabs-content {
  display: none;

}

/* .rs-nav-horizontal .rs-nav-item{
  border-radius: 8px 8px 0px 0px;
  border: 1px solid var(--Action-Blue-300, #E6EEFF);
  background: var(--Action-Blue-100, #F7FAFF);
  box-shadow: 0px 2px 24px 1px rgba(225, 225, 225, 0.80);
  font-weight: 600;
  margin:0 5px;
} */

.clsTab .rs-nav-horizontal .rs-nav-item {
  height: 38px;
  width: 70px;
  border: 0;
  border-radius: 0;
  background: none;
  justify-content: center;
}

.rs-nav-horizontal .rs-nav-item.rs-nav-item-active {
  background: #202850;
  color: #FFF;
}


/* YEAR PICKER */
/* Custom React Datepicker Theme */

/* Calendar Icon */
.react-datepicker__calendar-icon {
  width: 1.2em;
  height: 1.2em;
  vertical-align: middle;
  margin-left: 0.5em;
}

/* Datepicker Container */
.react-datepicker-wrapper {
  display: inline-block;
  margin: 0em;
}

/* Datepicker Input Container */
.react-datepicker__input-container {
  position: relative;
  display: inline-block;
}

/* Datepicker Input */
.react-datepicker__input-container input {
  width: 100%;
  padding: 0.5em;
  font-size: 1em;
  border: 0px solid #ccc;
  outline: none;
  background: none;
  font-weight: 500;
  /* border-radius: 0.3em; */
}

/* Datepicker Popper */
.react-datepicker-popper {
  z-index: 1000;
}

/* Datepicker Header */
.react-datepicker__header {
  background-color: #394476;
  border-radius: 0.3em 0.3em 0 0;
  color: #fff;
  text-align: center;
  padding: 0.5em;
}

/* Previous and Next Navigation Icons */
.react-datepicker__navigation {
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
}

/* Previous Navigation Icon */
.react-datepicker__navigation--previous {
  left: 0.5em;
}

/* Next Navigation Icon */
.react-datepicker__navigation--next {
  right: 0.5em;
}

/* Month Container */
.react-datepicker__month-container {
  display: flex;
  flex-wrap: wrap;
  max-width: 250px;
}

/* Month */
.react-datepicker__month {
  width: 33.33%;
  padding: 0.5em;
  text-align: center;
}

/* Month Text */
.react-datepicker__month-text {
  display: block;
  cursor: pointer;
  padding: 0.5em;
  border-radius: 0.3em;
}

/* Selected Month */
.react-datepicker__month-text--selected {
  background-color: #3dcc4a;
  color: #fff;
}

/* Selected Day */
.react-datepicker__day--selected {
  background-color: #3dcc4a;
  color: #fff;
  border-radius: 50%;
}

/* Today Button */
.react-datepicker__today-button {
  background-color: #2d3660;
  color: #fff;
  border: none;
  border-radius: 0 0 0.3em 0.3em;
  cursor: pointer;
  text-align: center;
  padding: 0.5em;
  width: 100%;
}

/* Today Button Hover */
.react-datepicker__today-button:hover {
  background-color: #1d5d90;
}

/* Close Icon */
.react-datepicker__close-icon::after {
  background-color: #fff;
  color: #2d3660;
  border-radius: 50%;
  font-size: 0.8em;
  line-height: 1;
  content: "×";
  display: inline-block;
  width: 1em;
  height: 1em;
  text-align: center;
}

/* Close Icon Hover */
.react-datepicker__close-icon:hover::after {
  background-color: #ccc;
}

.react-datepicker__year .react-datepicker__year-text {
  display: inline-block;
  width: 47%;
  margin: 2px;
  padding: 7px;
}


.react-datepicker-wrapper .custom-datepicker-button {
  /* width: 70px; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 0;
  background: none;
  padding: 0;
  gap: 10px;
  font-weight: 500;
}

.custom-datepicker-button i {
  color: #8e8e93;
}

.flag-icon {
  display: none;
}

.css-1nmdiq5-menu {
  top: 50% !important;
}

.currency_dropdown_col_filter .css-1nmdiq5-menu {
  top: 90% !important;
}

.clsDropdown__menu{
  margin-top: -12px !important;
}


@media(min-width:1640px) {
  .company_table_list th {
    font-size: 18px;
  }

  .company_details_form h2 {
    font-size: 28px;
  }

  .btn_cancel_form {
    width: 200px;
    height: 52px;
    font-size: 20px;
  }

  .btn_save_form {
    width: 200px;
    height: 52px;
    font-size: 20px;
  }
}

.rs-tooltip {
  padding: 6px 10px;
  max-width: 300px;
  background-color: #FFF9EF;
  color: #141B3C;
  font-weight: 500;
  box-shadow: 0 0 3px #f7d2d2;
}

.rs-tooltip[class*=placement-top]:after{
  border-top-color: #ffdfdf;
  border-width: 8px 8px 0;
  bottom: -8px;
  margin-left: -8px;
}

.rs-tooltip[class*=placement-bottom]:after{
  border-bottom-color: #ffdfdf;
  border-width: 0 8px 8px;
  margin-left: -8px;
  top: -8px;
}

.rs-tooltip[class*=placement-right]:after{
  border-right-color: #ffdfdf;
  border-width: 8px 8px 8px 0;
  left: -8px;
  margin-top: -8px;
}

.project_task_row h5 a {
  text-decoration: none;
  color: #2d2d2d;
}

.css-14h4o58-menu {
  bottom: 60% !important;
  z-index: 99 !important;
}

.clsScreeningButton {
  padding: 12px;
  /* position: fixed; */
  /* bottom: 0px;
  width: calc(35% - 25px); */
  /* width:34%; */
  /* right: 30px; */
  z-index: 1;
  border-radius: 0 !important;
}

.clsScreeningButton .btn_cancel_form {
  font-size: 16px;
  width: 150px;
  height: 40px;
}

.clsScreeningButton .btn_save_form {
  font-size: 16px;
  width: 150px;
  height: 40px;
}

.table_main_report tr {
  transition: visibility 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.table_main_report .accordion {
  cursor: pointer;
}

.accordion {
  cursor: pointer;
}

.panel {
  transform: translateY(-100%);
  transition: transform 0.3s ease-out, visibility 0.3s ease-out;
  visibility: hidden;
}

.panel.open {
  transform: translateY(0);
  max-height: 2000px;
  visibility: visible;
}

.panel td {
  display: none;
}

.panel.open td {
  display: table-cell
}

.select_box i.icon-arrow_caret {
  font-size: 12px;
}

/* .select_box .css-1dimb5e-singleValue {
  display: none;
} */


.clsNoData {
  font-size: 20px;
  text-align: center;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 73vh;
  color: #A6A6A6;
  font-weight: 500;
  gap: 12px;
}

.upper_case {
  text-transform: uppercase;
}

.loader__icon {
  animation: scaleAnimation 2s infinite;
}

@keyframes scaleAnimation {

  0%,
  100% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }
}

.highlight,
.highlightKeywords {
  background-color: yellow;
}


.rpv-search__highlight {
  background-color: rgba(255, 235, 59, 0.2) !important;
}

.pdf-container {
  position: relative;
  height: 97vh;
  /* Ensure the container takes the full height of the viewport */
  overflow: hidden;
  /* Prevent overflow to keep the toolbar fixed */
}

.custom-toolbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  /* Ensure it is above other elements */
  background-color: white;
  /* Background color to match the toolbar */
  padding: 10px 0;
}

.rpv-core__viewer {
  height: calc(100vh - 50px);
  /* Adjust based on the height of the toolbar */
  overflow-y: auto;
  /* Enable vertical scrolling */
  margin-top: 50px;
  /* Adjust based on the height of the toolbar */
}

.tabs_flex_row {
  display: flex;
  justify-content: space-between;
}

.btn_invite_analyst {
  border-radius: 4.109px;
  border: 1px solid #BCCDF2;
  background: #BCCDF2;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  height: 40px;
  padding: 8.219px 12.328px;
  text-decoration: none !important;
  color: #141B3C !important;
  leading-trim: both;
  text-edge: cap;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 29.86px;
}

.btn_addNew_role {
  display: flex;
  height: 40px;
  padding: 8.219px 12.328px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  text-decoration: none !important;
  color: #039 !important;
  leading-trim: both;
  text-edge: cap;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 29.86px;
  /* 161.475% */
  border-radius: 4.109px;
  border: 1px solid var(--Action-Blue-400, #BFD4FF);
  background: var(--Action-Blue-300, #E6EEFF);
}

.btn_save_role {
  text-decoration: none !important;
  color: #2D2D2D !important;
  leading-trim: both;
  text-edge: cap;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 29.86px;
  /* 161.475% */
  display: flex;
  height: 40px;
  padding: 8.219px 12.328px;
  justify-content: center;
  align-items: center;
  border-radius: 4.109px;
  border: 1px solid var(--Bright-Yellow-300, #FEE3B0);
  background: var(--Bright-Yellow-200, #FEEED0);
}

.TabMenu {
  display: flex;
  justify-content: center;
  align-items: center;
}

.TabMenu .rs-tabs-content {
  display: none;
}

.TabMenu.rs-tabs .rs-nav-item {
  color: #2B3779;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  height: 60px;
  padding: 12px 40px 24px 40px;
  border-radius: 8px 8px 0px 0px;
  background: var(--Action-Blue-100, #F7FAFF);
  box-shadow: 0px 2px 24px 1px rgba(225, 225, 225, 0.80);
}

.TabMenu .rs-nav-horizontal .rs-nav-item.rs-nav-item-active {
  color: #FFF;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  height: 60px;
  padding: 12px 40px 24px 40px;
  border-radius: 8px 8px 0px 0px;
  border: 1px solid var(--Action-Blue-300, #E6EEFF);
  background: #141B3C;
  box-shadow: 0px 2px 24px 1px rgba(225, 225, 225, 0.80);
}

.TabMenu .rs-nav {
  display: flex;
  gap: 10px;
}

.tab_content_main {
  background-color: #FFF;
  border-radius: 20px;
  position: relative;
  z-index: 1;
  margin-top: -12px;
  padding: 0px;
  padding-bottom: 20px;
}

.roles_col {
  display: flex;
  padding: 24px;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  border-radius: 8px;
  background: #FFF;
  box-shadow: 0px 2px 24px 1px rgba(225, 225, 225, 0.80);
  margin-top: 20px;
  height: calc(100% - 20px);
}

.text_roles{
  width: calc(100% - 60px);
}

.text_roles h5 {
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.text_roles h6 {
  color: rgba(45, 45, 45, 0.50);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 5px;
}

.text_roles p {
  color: #2D2D2D;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text_roles p span {
  color: #2B3779;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.img_roles {
  width: 60px;
  height: 60px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.img_roles img {
  max-width: 100%;
  max-height: 100%;
  border-radius: 50%;
  border: 1px solid #F5F5F5;
}

.tab_content_inner {
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 50px;
  min-height: 75vh;
}

.pagination_fixed_row {
  display: flex;
  justify-content: center;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  padding: 20px 12px;
  background: linear-gradient(0deg, #FFF 0%, #FFF 0%, rgba(255, 255, 255, 0.90) 0.01%, rgba(255, 255, 255, 0.75) 50%, rgba(255, 255, 255, 0.20) 100%, rgba(255, 255, 255, 0.35) 100%);
}

.tab_btns_group {
  position: absolute;
  right: 0;
  top: -3rem;
  display: flex;
  align-items: center;
  gap: 10px;
}

.roles_permissions_table_list td {
  padding-top: 10px;
  padding-bottom: 10px;
}

.roles_module_name {
  font-weight: 600 !important;
}

.searchfield_tab{
  height: 44px;
  padding: 12px 0px;
  border-radius: 4px;
  border: 1.4px solid var(--Faint-Navy-200, #D7E1F7);
  background: var(--Faint-Navy-100, #F2F5FC);
}

.searchfield_tab input{
  color: #2d2d2d;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  padding-left: 8px;
}

.searchfield_tab .btn_search{
  position: relative;
  color: #BCCDF2;
}

.searchfield_tab input::-webkit-input-placeholder {
  color: #BCCDF2;
  font-size: 16px;
  font-weight: 500;
}

.searchfield_tab input::-moz-placeholder {
  color: #BCCDF2;
  font-size: 16px;
  font-weight: 500;
}

.searchfield_tab input:-ms-input-placeholder {
  color: #BCCDF2;
  font-size: 16px;
  font-weight: 500;
}

.searchfield_tab input:-moz-placeholder {
  color: #BCCDF2;
  font-size: 16px;
  font-weight: 500;
}

.user_modals .modal-dialog{
  max-width: 830px;
}

.user_profile_row{
  display: flex;
  align-items: center;
  border-radius: 8px;
  background: #F2F7FF;
  gap: 20px;
}

.user_profile_pic{
  display: flex;
  width: 200px;
  padding: 20px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid var(--Action-Blue-400, #BFD4FF);
  background: #FFF;
}

.avatar-upload{
  width: 139px;
  height: 164px;
  border-radius: 16px;
  border: 1px dashed var(--Action-Grey-600, #696969);
  background: var(--Action-Grey-200, #F5F5F5);
}

.avatar-upload2{
  width: 139px;
  height: 164px;
  border-radius: 16px;
  border: 1px dashed var(--Action-Grey-600, #696969);
  background: var(--Action-Grey-200, #F5F5F5);
}

.image-uploaded{
  border: 1px solid var(--Action-Grey-300, #E1E1E1);
}

/* UPLOAD PROFILE PIC */
.avatar-upload {
  position: relative;
  max-width: 205px;
  margin: 0;
  overflow: hidden;
}

.avatar-edit {
  z-index: 1;
}

.avatar-edit input {
  display: none;
}

.avatar-edit label {
  display: inline-block;
  cursor: pointer;
  font-weight: normal;
  transition: all 0.2s ease-in-out;
  display: flex;
  height: 38px;
  padding: 3px 8px;
  padding-bottom: 4px;
  align-items: center;
  /* gap: 44px; */
  color: #141B3C;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 4px;
  border: 1px solid var(--Faint-Navy-300, #BCCDF2);
}

.btn__upload__remove{
  display: flex;
  width: 114px;
  height: 38px;
  padding: 3px 8px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  color: var(--Dark-Navy-800, #141B3C);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 4px;
  border: 1px solid var(--Action-Grey-400, #C3C3C3);
  background:none;
}

.avatar-upload2 .avatar-preview {
  width: 137px;
  height: 162px;
  position: relative;
  border-radius: 16px;
}

.avatar-preview {
  width: 139px;
  height: 164px;
  position: relative;
  border-radius: 16px;
}

.avatar-preview > div {
  width: 100%;
  height: 100%;
  border-radius: 16px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.task_asign_link {
  position: relative;
}

@keyframes scaleUp {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}


/* .tooltip_form {
  position: absolute;
  background-color: #FFF;
  transform: translate(35px, -67px);
  padding: 0px;
  border-radius: 5px;
  z-index: 9999;
  opacity: 1;
 border-radius: 8px;
 max-width: 450px;
 width: 90%;
 box-shadow: 0px 2px 24px 1px rgba(225, 225, 225, 0.80), 0px 2px 24px 1px rgba(225, 225, 225, 0.80);
 animation: scaleUp 0.3s ease forwards;
  opacity: 0;
  margin-top: -68px;
  margin-left: 40px;

} */

.tooltip_form {
  position: absolute;
  background-color: #FFF;
  transform: translate(45px, -67px);
  padding: 0px;
  z-index: 9999;
  opacity: 1;
  border-radius: 8px;
  max-width: 450px;
  width: 85%;
  box-shadow: 0px 2px 24px 1px rgba(225, 225, 225, 0.80), 0px 2px 24px 1px rgba(225, 225, 225, 0.80);
  animation: scaleUp 0.3s ease forwards;
  opacity: 0;
  margin-top: -60px;
  margin-left: 50px;
}

.tooltip_form_right {
  position: absolute;
  background-color: #FFF;
  transform: translate(45px, -67px);
  padding: 0px;
  z-index: 9999;
  opacity: 1;
  border-radius: 8px;
  max-width: 450px;
  width: 85%;
  box-shadow: 0px 2px 24px 1px rgba(225, 225, 225, 0.80), 0px 2px 24px 1px rgba(225, 225, 225, 0.80);
  animation: scaleUp 0.3s ease forwards;
  opacity: 0;
  margin-top: -60px;
  margin-right: 50px;
}

.tooltip_form.show {
  opacity: 1; /* Visible when the class is added */
}

/* Create the arrow */
.tooltip_form::after {
  content: '';
  position: absolute;
  left: -18px;
  top: 45px;
  border-top: 13px solid transparent;
  border-right: 20px solid #FFF;
  border-left: none;
  border-bottom: 13px solid transparent;
}

.tooltip_form_right::after {
  content: '';
  position: absolute;
  right: -18px;
  top: 45px;
  border-top: 13px solid transparent;
  border-left: 20px solid #FFF;
  border-right: none;
  border-bottom: 13px solid transparent;
}

.tooltip_form_inner{
  padding: 20px 20px;
}

.tooltip_form_inner label{
  color: #696969;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 4px;
}

.tooltip_form_inner .rs-picker-toggle-indicator .rs-picker-caret-icon{
  height: 28px;
  font-size: 16px;
  top: 4px !important;
}

.selectPickerTMMain .rs-picker-select-menu-item:hover, .selectPickerTMMain .rs-picker-select-menu-item:focus, .selectPickerTMMain .rs-picker-select-menu-item.rs-picker-select-menu-item-focus{
  background-color: #F6F6F6 !important;
}

.tooltip_form h5{
  background: var(--Faint-Navy-100, #F2F5FC);
  padding: 12px;
  text-align: center;
  color: #2D2D2D;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 0 !important;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.tooltip_form_inner .rs-picker{
  border-radius: 4px;
  border: 1px solid var(--Action-Grey-300, #E1E1E1);
  height: 50px;
  background-color: #F5F5F5;
  display: flex;
  align-items: center;
}

.selectPickerTMMain{
  border-radius: 8px;
  background: #FFF;
  box-shadow: 0px 2px 24px 1px rgba(225, 225, 225, 0.80);
  margin-top: 15px;
}

.selectPickerTMMain img{
  border-radius: 50%;
  border:1px solid #F5F5F5;
}


.rs-picker-default .rs-picker-toggle.rs-btn{
  box-shadow: none !important;
}

.tooltip_form_inner .rs-picker-toggle-placeholder{
color:  #4B4B4B;
font-feature-settings: 'clig' off, 'liga' off;
font-size: 15px;
font-style: normal;
font-weight: 500;
line-height: 24px; /* 150% */
}

.tooltip_form .btn__group{
  margin-top: 35px !important;
}

.drop_title{
color: #000;
font-size: 17px;
font-style: normal;
font-weight: 600;
line-height: normal;
}

.drop_designation{
color: rgba(45, 45, 45, 0.50);
font-size: 13px;
font-style: normal;
font-weight: 500;
margin-top: 4px;
line-height: normal;
}

.btn__group{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
}

.btn__cancel{
  display: flex;
  width: 134px;
  height: 44px;
  padding: 3px 8px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 4px;
  border: 1px solid var(--Bright-Yellow-600, #FDC152);
  text-decoration: none !important;
  color: var(--Dark-Navy-800, #141B3C)!important;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.btn__assign{
  display: flex;
  width: 134px;
  height: 44px;
  padding: 3px 8px;
  justify-content: center;
  align-items: center;
  text-decoration: none !important;
  flex-shrink: 0;
  border-radius: 4px;
  background: var(--Bright-Yellow-400, #FDD791);
  color: var(--Dark-Navy-800, #141B3C) !important;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.tooltip_form_inner .rs-picker-toggle-value{
  color: var(--Action-Grey-900, #000);
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}

.title_flex_sp{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.user_modals .modal-header{
  display: flex;
  padding: 16px 20px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  align-self: stretch;
  border-radius: 16px 16px 0px 0px;
  background: var(--Faint-Navy-100, #F2F5FC); 
  border: 0;
}

.user_modals .modal-title{
  color: #000;
  font-size: 24px;
  font-style: normal;
  line-height: normal;
  font-weight: 500;
}

.user_modals .modal-content{
  border-radius: 16px;
  border:0;
}

.user_profile_pic p{
color: var(--Action-Grey-800, #2D2D2D);
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
}

.user_profile_details{
  width: calc(100% - 200px);
  padding-right: 24px;
}

.user_profile_details h5{
  color: var(--Action-Grey-900, #000);
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.date_time_status{
  display: flex;
  height: 34px;
  padding: 4px 13px;
  justify-content: center;
  align-items: center;
  gap: 3px;
  flex-shrink: 0;
  border-radius: 100px;
  border: 1px solid var(--Action-Red-300, #FECDCD);
  background: var(--Action-Red-100, #FFF2F2);
  color: var(--Action-Grey-900, #000);
  text-align: center;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.status_time{
  color: #696969;
}

.date_time_status .red_circle_status{
  width: 12px;
  height: 12px;
  background-color: #DA293E;
  border-radius: 50%;
  display: block;
}

.user_profile_details h6{
  color: rgba(45, 45, 45, 0.50);
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 9px;
}

.user_profile_details p{
color: var(--Action-Grey-800, #2D2D2D);
font-size: 15px;
font-style: normal;
font-weight: 400;
line-height: normal;
margin-top: 12px;
}

.user_profile_details p span{
  color: var(--Dark-Navy-700, #2B3779);
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.user_profile_details .btn__group{
  justify-content: flex-start;
  margin-top: 16px;
}

.profile_form_row .form-control{
  display: flex;
  height: 45px;
  padding: 8px 15px;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 4px;
  background: var(--Action-Grey-150, #F6F8FA);
  border:0;
  border-bottom: 2.4px solid #FEEED0;
}

.profile_form_row .rs-picker-has-value .rs-btn .rs-picker-toggle-value, 
.profile_form_row .rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value{
  font-size: 1rem !important;
  font-weight: 400 !important;
  line-height: 1.5;
  color:#212529 !important;
}

.profile_form_row .rs-picker-toggle .rs-picker-toggle-placeholder{
  font-weight: 400 !important;
  line-height: 1.5;
  color:#C3C3C3 !important;
  font-size: 15px !important;
}

.profile_form_row h5{
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  border-bottom: 1px solid var(--Action-Blue-400, #BFD4FF);
  padding: 8px 0px 12px 0px;
  margin-top: 26px;
}

.profile_form_row label{
  color: var(--Action-Grey-800, #2D2D2D);
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 4px;
}

.profile_form_row .rs-picker-default .rs-picker-toggle.rs-btn .rs-picker-toggle-indicator .rs-picker-caret-icon, 
.profile_form_row .rs-picker-default .rs-picker-toggle.rs-btn .rs-picker-toggle-indicator .rs-picker-clean{
  top: 9px !important;
}

.profile_form_row .rs-picker-default .rs-picker-toggle{
  padding-left: 15px;
}


.profile_form_row .icon_field svg{
  width: 20px;
  height: 15px;
}

.btn_group_modal .btn_cancel{
  border-radius: 4.109px;
  border: 1px solid #FDCC72;
  color: #141B3C;
  background: #FFF9EF;
  leading-trim: both;
  text-edge: cap;
  font-size: 16px;
  display: flex;
  align-items: center;
  height: 50px;
  font-style: normal;
  font-weight: 600;
  line-height: 29.86px; /* 161.475% */
}

.btn_group_modal .btn_save_changes{
  border-radius: 4.109px;
  border: 1px solid #FEE3B0;
  background: #FEE3B0;
  color: #2D2D2D;
  leading-trim: both;
  text-edge: cap;
  font-size: 16px;
  display: flex;
  align-items: center;
  height: 50px;
  font-style: normal;
  font-weight: 600;
  line-height: 29.86px; /* 161.475% */
  padding: 0 22px;
}

.profile_form_row ::-webkit-input-placeholder {
  color: #C3C3C3;
  font-size: 15px;
}

.profile_form_row ::-moz-placeholder {
  color: #C3C3C3;
  font-size: 15px;
}

.profile_form_row :-ms-input-placeholder {
  color: #C3C3C3;
  font-size: 15px;
}

.profile_form_row :-moz-placeholder {
  color: #C3C3C3;
  font-size: 15px;
}

.custom_select_drop{
  height: 45px; 
  background-color:#F6F8FA;
  display:flex; 
  align-items:center; 
  border-radius:4px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color:#212529;
  border-bottom: 2.4px solid #FEEED0;
}

.custom_select_drop.rs-picker-has-value{
  border-bottom: 2.4px solid transparent;
}

/* .custom_select_drop.rs-picker:not(.rs-picker-disabled):hover, .custom_select_drop.rs-picker.rs-picker-focused{
  border-color: transparent !important
} */

.default_preview_avtar{
  position: absolute;
  z-index: 0;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
  height: 100%;
}

.default_preview_avtar p{
  color: #4C535F;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}


.form_control_no_border{
  border-bottom: 2.5px solid transparent !important;
}

.report_delete_modals {
  text-align: center;
}

.report_delete_modals .modal-body{
  padding-top: 40px;
  padding-bottom: 40px;
}

.report_delete_modals h5{
  color: #141B3C;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.report_delete_modals p{
  color: #696969;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 15px;
}

.btn_delete{
  display: flex;
  width: 134px;
  height: 44px;
  padding: 3px 8px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: #FDD791 !important;
  color:#141B3C;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

/* ANIMATION FOR MODALS */
 @keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-30px);
  }
}

.modal.fade .modal-dialog {
  animation: fadeIn 0.3s forwards;
}

.modal.fade.hide .modal-dialog {
  animation: fadeOut 0.3s forwards;
} 

 /* @keyframes scaleIn {
  from {
    transform: scale(0.4);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes scaleOut {
  from {
    transform: scale(1);
    opacity: 1;
  }
  to {
    transform: scale(0.4);
    opacity: 0;
  }
}

.modal.fade .modal-dialog {
  transition: none;
  transform: scale(0.4);
  opacity: 0;
}

.modal.fade.show .modal-dialog {
  animation: scaleIn 0.4s forwards;
  transform: scale(1);
  opacity: 1;
}

.modal.fade:not(.show) .modal-dialog {
  animation: scaleOut 0.4s forwards;
}  */

.table_tooltip{
  border-radius: 8px;
  border: 1px solid #E1E1E1 !important;
  overflow: hidden;
}

.table_tooltip .table{
  margin-bottom: 0;
}

.table_tooltip thead{
  border-radius: 8px 8px 0px 0px;
}

.table_tooltip thead th{
  border-bottom: 1px solid #E1E1E1;
  background: #E1E1E1;
  color: #00203D;
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.6px;
}

.table_tooltip tbody td{
  border-top: 1px solid #E1E1E1;
  color: #00203D;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.48px;
}

.table_tooltip tbody tr:nth-child(even) td{
  background:#F6F8FA;
}

.btn__group_report_table{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
}

.btn__cancel_rtable{
  display: flex;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  height: 38px;
  border-radius: 4px;
  border: 1px solid #A1B9ED;
  color: #141B3C !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 125% */
}

.btn__mark_rtable{
  display: flex;
  padding: 8px 12px;
  align-items: center;
  height: 38px;
  justify-content: center;
  border-radius: 4px;
  background: #FDD791;
  color: #141B3C;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 125% */
}

.sector_title_desc_row{
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 16px 16px 0px 0px;
  background: #FFF9EF;
  box-shadow: 0px 2px 24px 1px rgba(225, 225, 225, 0.80);
  padding: 20px;
  padding-bottom: 32px;
  position: relative;
  margin-top: -12px;
  z-index: 1;
  gap: 20px;
}

.sector_title_desc_row h5{
  color: #2D2D2D;
  font-size: 26px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.sector_title_desc_row p{
  color:  #2D2D2D;
  font-size: 16px;
  font-style: normal;
  margin-top: 5px;
  font-weight: 400;
  line-height: normal;}

.btn_create_sector_group{
  display: flex;
  border-radius: 4.109px;
  border: 1px solid #BFD4FF;
  background: #E6EEFF;
  height: 40px;
  padding: 8.219px 12.328px;
  gap: 8px;
  white-space: nowrap;
  justify-content: center;
  align-items: center;
  color: #039 !important;
  leading-trim: both;
  text-edge: cap;
  text-decoration: none !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 29.86px; /* 161.475% */
}

.sector_industry_table{
  border-radius: 8px 8px 0px 0px;
  overflow: hidden;
  border:1px solid #E1E1E1;
}

.sector_industry_table thead th{
  background:#D7E1F7;
  padding: 8px 12px;
  color:#2D2D2D;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.sector_industry_table tbody td{
  padding: 8px 12px;
  /* border-left: 1px solid #E1E1E1; */
  border-top: 1px solid #E1E1E1;
  vertical-align: middle;
}

.sector_industry_table tbody tr:nth-child(even) td{
  background-color: #F7FAFF;
}

.sector_name_td_col{
  border-left: 1px solid #E1E1E1;
  border-right: 1px solid #E1E1E1;
  padding-left: 20px;
  color: #4B4B4B;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  min-height: 20px;
}

.sector_category_td_col{
  color: #2D2D2D;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  width: 286px;
}

/* .sector_industry_table tbody td:first-child{
  border-left: 0px solid #E1E1E1;
} */

.sector_action{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.sector_action a{
  font-size: 30px;
  color: #A1B9ED !important;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sector_action a.delete_section_icon{
  font-size:20px;
  border:1.5px solid #A1B9ED;
  border-radius: 50%;
  width: 30px;
  color: #DA293E !important;
  height: 30px;
}

.modal-backdrop.show{
  opacity: 0.2;
}

.modal-content{
  border-radius: 16px;
  overflow: hidden;
}

.create_sector_modals .modal-header{
  flex-direction: column;
  background: #F2F5FC;
  border-bottom: 0;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.create_sector_modals .modal-dialog{
  max-width: 790px;
}

.create_sector_modals h1.modal-title{
  color: #161616;
  font-size: 26px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.56px;
}

.create_sector_modals p{
  color: #696969;
  text-align: center;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.36px;
  margin-top: 4px;
}

.create_sector_modals .modal-body{
  padding: 50px 45px;
}

.rs-tag{
  border-radius: 3px;
  border: 1px solid #A1B9ED;
  background: #F2F5FC;
  padding: 5px 10px;
}

.rs-tag-md{
  color: #212529;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.36px;
  text-transform: capitalize;
}

.rs-tag .rs-btn-close .rs-icon{
  width: 12px;
  height: 12px;
}

.create_sector_modals .rs-picker-default .rs-picker-toggle.rs-btn .rs-picker-toggle-indicator .rs-picker-caret-icon, 
.create_sector_modals .rs-picker-default .rs-picker-toggle.rs-btn .rs-picker-toggle-indicator .rs-picker-clean{
  top:0;
  bottom: 0;
  margin: auto;
}

.create_sector_modals .form-control{
  border-radius: 4px;
  border-bottom: 2px solid var(--Bright-Yellow-600, #FDC152);
  background: var(--Beige-100, #F8F8F7);
  height: 48px;
  color:#2D2D2D;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.54px;
}



.create_sector_modals ::-webkit-input-placeholder {
  color: #717171;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.54px;
}

.create_sector_modals ::-moz-placeholder {
  color: #717171;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.54px;
}

.create_sector_modals :-ms-input-placeholder {
  color: #717171;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.54px;
}

.create_sector_modals :-moz-placeholder {
  color: #717171;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.54px;
}

.create_sector_modals .rs-picker-toggle .rs-picker-toggle-placeholder{
  color: #717171;font-size: 17px;font-style: normal;font-weight: 400;line-height: normal;letter-spacing: 0.54px;
}

.create_sector_modals label{
  color: #4B4B4B;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.64px;
  margin-bottom: 5px;
}

.custom_dropdown_menu .rs-checkbox-label{
  color: #2D2D2D;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.btn_review_subticker{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 12px;
  height: 40px;
  color: #FFF;
  text-align: center;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 8px;
  background: #141B3C;
}


.rs-modal-content{
  padding: 0;
  border-radius: 0;
}
/* TABLE COLUMN FIXED */
.table-scroll {
  position: relative;
  max-width: 100%;
  margin: auto;
  overflow: hidden;
}

.table-wrap {
  width: 100%;
  overflow: auto;
  display: block;
  max-height: calc(100vh - 110px) !important;
}

.main-table {
  width: 100%;
  border-collapse: separate;
}

.main-table th,
.main-table td {
  padding: 8px 16px;
  border-right: 1px solid #F5F5F5;
  white-space: nowrap;
}

.main-table td{
  color: #000;
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 500;
  vertical-align: middle;
}

.main-table th:last-child,
.main-table td:last-child{
  border-right: 0;
}

.fixed-side {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  background-color: #f9f9f9;
  z-index: 2 !important;
}

.main-table tr:nth-child(even) td{
  background-color: #F6F8FA;
}

.main-table thead{
  position: relative;
  z-index: 3 !important
}


.main-table thead th{
  background-color: #141B3C;
  color: #FFF;
  font-weight: bold;
  font-size: 18px;
  position: sticky;
  top: 0;
  z-index: 1;
}

.review_subticker_row td{
  background-color: #D7E1F7 !important;
}

.dought_color_row td{
  background-color: #FFF9EF !important;
}

.total_assets_row td{
  background-color: #E1E1E1 !important;
}

.subticker_field,
.subticker_field:focus{
  border-radius: 4px;
  border: 0.4px solid #C3C3C3;
  height: 34px;
  background-color: transparent;
  color:#000;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-left: 12px;
  padding-right: 12px;
  box-shadow: none;
}

.rs-modal-footer{
  border-radius: 0px 0px 16px 16px;
  background: var(--Bright-Yellow-100, #FFF9EF);
  box-shadow: 0px -2px 24px 1px rgba(225, 225, 225, 0.80);
  display: flex;
  padding: 12px 20px;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.rs-modal-body{
  max-height:calc(100%) !important;
}

.rs-modal{
  margin: 0 auto !important;
  width: 98% !important;
}

.full-width-modal .rs-modal-content {
  width: 100%;
}

.custom_modal_full .rs-modal-dialog {
  top: 0 !important; /* Set top position to 0 */
  transform: none !important; /* Remove any transform that might center it */
  margin: 0 !important; /* Ensure no margin */
  padding: 0 !important; /* Ensure no padding */
}

  .rs-modal-wrapper{
    /* top: -20px; */
    height: calc(100% + 20px)
  }

  /* ERROR LOADING ANIMATION */
  
  .rs-message.rs-message-has-title .rs-message-icon{
    align-self: center;
  }

  .rs-message{
    border-radius: 69px;
    /* box-shadow: 0px 2px 10px 1px rgba(0, 0, 0, 0.10);
    background: #FAFAFA; */
    box-shadow: 0px 2px 10px 1px rgba(0, 0, 0, 0.03);
    background: #FFF;
    border: 1px solid #f9f3f3;
  }

  .rs-message-container{
    padding: 0;
    padding-right: 30px;
  }

  .rs-message-content{
    align-self: center;
    padding-right: 50px;
  }

  .rs-message-info .rs-message-container,
  .rs-message-success .rs-message-container,
  .rs-message-error .rs-message-container
  {
    background: #FFF;
  }

  .icon_error_msg{
    display: flex;
    padding: 22px;
    align-items: flex-start;
    gap: 10px;
    border-radius: 69px;    
  }

.Icon_info{
  background: #E6EEFF;
}

.Icon_success{
  background: #EAF9E8;
}

.Icon_error{
  background: #FFE7E7;
}

  .error_msg_text h5{
    font-size: 17px;
    font-style: normal;
    font-weight: bold;
    line-height: normal;  
  }

  .error_msg_info_text h5{
    color:#517DDE;
  }

.error_msg_success_text h5{
  color:#0A8F43;
}

.error_msg_error_text h5{
  color:#D92A3F;
}

.error_msg_text p{
  color: #000;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 4px;
  }

  .rs-message-container .rs-btn-close{
    bottom: 0;
    right: 16px;
    color: #878787 !important;
    font-size: 15px;
  }
  
.rs-btn-close svg path{
  stroke-width: 1px;
  stroke: #878787;
  
}
.icon_error_msg_inner {
  /* Assuming you have some styles for positioning */
  position: relative;
  width: 33px;
  height: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.progress-circle {
  position: absolute;
  width: 33px;
  height: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1.6px solid rgba(0, 0, 0, 0.10);
  animation: spin 1s linear infinite;
}

.progress_circle_info{
  border-top-color: rgba(107, 145, 227, 0.75);
}

.progress_circle_error{
  border-top-color: rgba(218, 41, 62, 0.75);
}

.progress_circle_success{
  border-top-color: rgba(10, 143, 67, 0.75);
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.table_ranking thead th{
  font-family: 'Raleway';
  font-weight: bold;
  letter-spacing: 0;
}

.rs-rate-character .rs-icon{
  height: 23px;
  width: 23px;
}

.rs-rate-md .rs-rate-character{
  height: 27px;
  width: 23px;
}

.table_ranking td{
  padding: 5px 15px;
}

.rs-rate-character-full .rs-rate-character-after>.rs-icon{
  fill:#F5B922
}

.rating_list{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.rating_list li svg{
  width: 24px;
  height: 24px;
}

.table_zero_data{
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  text-align: center;
  flex-direction: column;
  height: 69vh;
  color: #A6A6A6;
  font-weight: 500;
  gap: 12px;
  width: 100%;
}

.zero_data_content{
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  text-align: center;
  height: 97vh;
  color: #A6A6A6;
  font-weight: 500;
  width: 100%;
  background-color: #FFF;
  border-radius: 6px;
}

.zero_data_content p{
  margin-top: 10px;
}

.project_task_report, 
.project_task_row{border:1px solid transparent; transition: all 0.4s;}

.project_task_report:hover,
.project_task_row:hover{border:1px solid #dae1ef;background-color: #FFF !important;}

.task_row .rs-dropdown-toggle{
  transition: all 0.3s;
  /* transform: rotate(0deg); */
  width: 34px;
  height: 34px;
  background: none !important;
  border-radius: 50%;
}

.task_row .rs-dropdown-toggle:hover{
  /* transform: rotate(180deg); */
  background-color: #e5eaff !important;
}

.login_svg_anim{
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index:-1
}


.scroll-container {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: relative;
}

.scroll rect {
  animation: scroll 15s linear infinite;
}

@keyframes scroll {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(50%);
  }
}

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:hover{
  background-color: rgba(255,255,255,0.2);
  border-radius: 8px;
}

.tablel_field input{
  color: #999;
}

.project_task_row .task_tag{
  width: 120px;
}


.project_task_row .task_nav li{
  font-size: 12px;
}

.login_header_text, 

.login_logo{

  position: relative;

  z-index:1,

}



.comment_modal_body .rs-modal-content{

  border-radius: 16px !important;

}



.comment_modal_body{

  margin: 0 !important;

  width: 55% !important;

  margin-left: 0px !important;

  position: absolute;

  bottom: 23px;

}



.comment_modal_body .rs-modal-content{

  background: #FFF9EF;

  border: 1px solid #E1E1E1;

}



.comment_modal_header{

  border-radius: 16px 16px 0px 0px !important;

  background: #F2F5FC !important;

  text-align: center;

  padding: 15px 0;

}



.comment_modal_header h4{

  color: #161616;

  font-size: 24px;

  font-style: normal;

  font-weight: 600;

  line-height: normal;

  letter-spacing: 0.5px;

}



.comment_modal_header .rs-modal-header-close{

  opacity: 0 !important;

}

.comment_modal_content{

  padding: 0 30px;

}



.comment_modal_content h4{

  color: #161616;

  font-size: 20px;

  font-style: normal;

  font-weight: 600;

  line-height: normal;

  letter-spacing: 0.48px;

}



.comment_modal_box{

  padding: 12px 20px;

  margin-top: 14px;

  border-radius: 8px;

  background: #FFF;

}



.comment_modal_box h5{

  color: #2D2D2D;

  font-size: 18px;

  font-style: normal;

  font-weight: 600;

  line-height: normal;

}



.comment_modal_box p{

  color: #2D2D2D;

  font-size: 17px;

  font-style: normal;

  font-weight: 400;

  line-height: 26px; /* 133.333% */

  margin-top: 16px;

}



.flex_sp_row{

  display: flex;

  align-items: center;

  justify-content: space-between;

}



.comment_modal_box_top .comment_date{

  color: #878787;

  font-size: 14px;

  font-style: normal;

  font-weight: 500;

  line-height: normal;

}



.comment_modal_box_bottom{

  margin-top: 14px;

}



.comment_modal_box_bottom h6{

  color: #3669D9;

  font-size: 18px;

  font-style: normal;

  font-weight: 500;

  line-height: normal;

}



.cdelete_icon{

  width: 34px;

  height: 34px;

  display: flex;

  align-items: center;

  justify-content: center;

  border: 1px solid #D7E1F7;

  background-color: #FFF;

  color: #DA293E !important;

  border-radius: 50%;

  text-decoration: none !important;

  font-size: 18px;

}



.comment_modal_body .rs-modal-body {

  max-height: calc(100vh - 365px) !important;

  min-height: calc(100vh - 365px) !important;

  overflow: auto;

}



.modal_footer_comment_form{

  border-radius: 16px;

  background: #FFF !important;

  box-shadow: 0px -4px 20px 0px rgba(0, 0, 0, 0.20) !important;

  border:0 !important

}



.modal_footer_comment_inner{

  width: 100%;

  text-align: left;

}



.modal_footer_comment_inner label{

  color: #4B4B4B;

  font-size: 16px;

  font-style: normal;

  font-weight: 500;

  line-height: normal;

  letter-spacing: 0.64px;
}

.modal_footer_comment_inner .form-control{

  border:0 !important;

  box-shadow: none !important;

  border-radius: 4px !important;

  border-bottom: 2px solid #FDC152 !important;

  background: #F8F8F7 !important;

  height: 46px !important;

}



.modal_footer_comment_inner .btn_browse{

  font-weight: 500;

  border-bottom: 2px solid #fdc152;

}



.modal_footer_comment_inner .btn__group{

  margin-top: 30px;

}



.modal_footer_comment_inner .btn_cancel{

  width: 200px;

  height: 50px;

  color: #4B4B4B;

  font-size: 18px;

  font-weight: 500;

  letter-spacing: 0.36px;

}



.modal_footer_comment_inner .btn_comment{

  border-radius: 4px;

  background: #FDD791;

  border:0;

  width: 200px;

  height: 50px;

  color: #141B3C;

  font-size: 18px;

  font-style: normal;

  font-weight: 600;

  line-height: normal;

  letter-spacing: 0.36px;

}



.zero_comment{

  display: flex;

  align-items: center;

  justify-content: center;

  flex-direction: column;

  gap: 20px;

  font-size: 22px;

  color: #696969;

  font-weight: 600;

  height: calc(100vh - 470px) !important;

}



.company_table_list  .table tr:hover td{

  background-color: #e8edf9;

}



.rs-popover[class*=placement-right]>.rs-popover-arrow:after{

  border-right-color: #fff;

  border-right-color: var(--rs-bg-overlay);

  border-width: 6px 16px 16px 0;

  left: -12px;

  margin-top: -8px;

}



.rs-popover{

  border-radius: 8px;

  -webkit-filter: drop-shadow(0 1px 8px rgba(0, 0, 0, .05));

  filter: drop-shadow(0 1px 8px rgba(0, 0, 0, .05));

  box-shadow: 0 0 15px #d9d9d9;

}



.popover_form_inner{

  padding: 20px 15px;

}



.component_column{

  background-color: #FFF;

  box-shadow: 0 0 8px #DDD;

  border-radius: 10px;

  padding: 15px;

  height: 100%;

}

.tagpicker{

  border-radius: 8px;

  border: 1px solid #E1E1E1;

  background: #F8F8F7;

  display: flex;

  align-items: center;

  padding: 0px;

}
.tagpicker .rs-picker-input{

  background: none !important;

  border:0 !important;
}


.tagpicker .rs-picker{

  width: 100% !important;

}

.company_table_list .rs-table-cell-header .rs-table-cell-content {

  border-bottom: 1px solid #D7E1F7;

  background: #FFF9EF;

  color: #00203D;

  font-family: 'Raleway';

  font-size: 16px;

  font-weight: bold;

  letter-spacing: 0.6px;

  vertical-align: middle;

  white-space: nowrap;

}



.rs-table-cell-header-icon-sort{

  color: #8e9fb1;

}

.rs-btn-link{

  color: #3d3ae9;

}

.rs-table-cell-content{
  padding: 11px 10px;
}



.rs-table-cell-header .rs-table-cell-content{

  padding: 7px 10px;

}



.status_cell {

  border-radius: 24px;

  text-align: center;

  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 12px;

    font-style: normal;
  
    font-weight: 600;
  
    line-height: 18px;
  
    letter-spacing: 1px;
  
    text-transform: uppercase;
  
    display: flex;
  
    width: 130px;
  
    height: 28px;
  
    justify-content: center;
  
    align-items: center;
  }
  
  
  .status_cell.uncovered {
  
    background: #E1E1E1;
  
    color: #2D2D2D;
  
  }
  
  
  
  .status_cell.not.halal {
  
    background: #FFE0E0;
  
    color: #BD000B;
  
  }
  
  
  
  .status_cell.halal {
  
    background: #EAF9E8;
  
    color: #0E5A29;
  
  }
  

  .status_cell.doughtful {

    background: #FFF4CC;
  
    color: #C79005;
  }


.rs-picker-popup .rs-search-box{
  padding: 6px 0px 6px !important;
  border-bottom: 1px solid #ddd !important;
}

.fxCurrency {
  min-width: auto !important;
  width: 115px;
}

.btn_report_load{
  display: flex;
  width: 134px;
  height: 44px;
  padding: 3px 8px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: #FDCC72;
  border:0;
  outline:none;
  color: #141B3C;
  font-size: 16px;
  font-weight: 500;
  }

  .report_load_desc{
    color: #4B4B4B;
    text-align: center;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 0.24px;
    }

    .pagination .page-link .fa{
      font-size: 20px;
    }


    .password_error_msg{
      margin-top: 12px !important;
    }
    
    .password_error_msg li{
    font-weight: 500;
    font-size: 14px;
    color: #696969;
    font-family: 'Inter';
    line-height: 26px;
    gap: 8px;
    }
    
    .password_error_msg svg{
      width: 10px !important;
      height: 10px !important;
      display: none;
    }
    
    .password_error_msg li span{
      opacity: 1 !important;
      padding-top: 0 !important;
    }
    
    .password_error_msg li.invalid{
      color: #DA293E;
    }
    
    .password_error_msg li.valid{
      color: #0A8F43;
    }
    
    /* Add this to your CSS file */
    
    .password_error_msg ul {
      /* list-style-type: disc; */
      /* padding-top: 20px; */
    }
    
    .password_error_msg li {
      display: flex;
      align-items: center;
      margin-bottom: 5px;
      
    }
    
    .password_error_msg li::before {
      content: '';
      color: #000; 
      height: 8px;
      width: 8px;
      border-radius: 50%;
      background: #DDD;
    }
    
    
    .password_error_msg li.valid::before,
    .password_error_msg li.invalid::before {
      content: '';
      border-radius: 0%;
      background:none;
      height: 0px;
      width: 0px;
    }
    
    /* .password_error_msg li.valid svg, 
    .password_error_msg li.invalid svg{
      display: block;
    }   */
    
     .password_error_msg li.valid::before {
      content: '';
      display: inline-block;
      width: 10px;
      height: 10px;
      background: url('../images/IconValid.svg') no-repeat center center / contain;
    }
    
    .password_error_msg li.invalid::before {
      content: '';
      display: inline-block;
      width: 10px;
      height: 10px;
      background: url('../images/IconInvalid.svg') no-repeat center center / contain;
    } 
    
    .Rsuite_popover .rs-popover-arrow{
      display: none !important;
    }
    
    .video_login {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: 0;
    }

    .Rsuite_popover{
      max-width: 400px;
      width: 100%;
      
    }

    .btn_assign{
  background: none !important;
  padding: 0 !important;
}
 
.Rsuite_popover .rs-picker-default .rs-picker-toggle.rs-btn .rs-picker-toggle-indicator .rs-picker-clean{
top: 10px !important;
}


.project_task_report:hover .ticker_locate,
.project_task_row:hover .ticker_locate,
.project_task_row:hover .ticker_country{
  background-color: #f7f7f7;
}

.form_control_common,
.form_control_common:focus{
  height: 40px;
  border-radius: 4px;
  border: 0;
  background-color: #F8F8F7;
  border-bottom: 2px solid #FDCC72;
  box-shadow: none;
  color: #000;
}

.value_tooltip_form .tooltip_form_inner {
  padding: 10px;
}

.value_tooltip_form .input-group-text {
  height: 40px;
  border: 0;
  border-radius: 4px;
  background-color: #F8F8F7;
  border-bottom: 2px solid #fdcc72;
  padding: 0 10px;
  min-width: 30px;
  text-align: center;
  justify-content: center;
  border-right: 1px solid #efe8da;
  z-index: 10;
  position: relative;
  color: #606060;
  font-size: 14px;
}

.btn__save_value{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  color: #141B3C;
  text-align: center;
  font-size: 16px;
  padding: 0 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-radius: 4px;
  border: 1px solid #FDCC72;
  background: #FDCC72;
}

.value_tooltip_form h5{
  background: var(--Faint-Navy-100, #F2F5FC);
  padding: 12px;
  text-align: center;
  color: #2D2D2D;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 0 !important;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.value_tooltip_form label{
  font-weight: 500;
  margin-bottom: 6px;
}

.btn__save{
  width: 110px;
  height: 42px;
  color: #141B3C;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-radius: 8px;
  border: 1px solid var(--Bright-Yellow-500, #FDCC72);
  background: var(--Bright-Yellow-500, #FDCC72);
}

.action_disable{
  cursor:default;
  pointer-events: none;
}

.action_disable i{
  color: #d7d0d0;
}

.company_table_list .form-check{
  margin-bottom: 0;
  display: flex;
  align-items: center;
  gap: 5px;
  }

  .react-datepicker__day--in-range, 
.react-datepicker__day--in-selecting-range, 
.react-datepicker__day--selected, 
.react-datepicker__month-text--in-range, 
.react-datepicker__month-text--in-selecting-range, 
.react-datepicker__month-text--selected, 
.react-datepicker__quarter-text--in-range, 
.react-datepicker__quarter-text--in-selecting-range, 
.react-datepicker__quarter-text--selected, 
.react-datepicker__year-text--in-range, 
.react-datepicker__year-text--in-selecting-range, 
.react-datepicker__year-text--selected{
  background-color: #343c60;
}

.react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover, .react-datepicker__month-text--selected:hover, .react-datepicker__month-text--in-selecting-range:hover, .react-datepicker__month-text--in-range:hover, .react-datepicker__quarter-text--selected:hover, .react-datepicker__quarter-text--in-selecting-range:hover, .react-datepicker__quarter-text--in-range:hover, .react-datepicker__year-text--selected:hover, .react-datepicker__year-text--in-selecting-range:hover, .react-datepicker__year-text--in-range:hover{
  background-color: #343c60;
}
.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected{
  background-color: #343c60;
  color: #FFF;
}

.rs-popover[class*=placement-right]{
  margin-left: 20px !important;
}

.disable_blur{
  filter: blur(2px);
  /* pointer-events: none; */
  cursor: not-allowed;
  opacity: 0.6;
  user-select: none;
}

.disable_blur a, .disable_blur ul{
  pointer-events: none;  
  user-select: none;
}


.clsTooltipDisable{
  display: none !important;
}

.clsTooltipEnable{
  display: block !important;
}



.page-item .form-control{
  width: 60px;
  height: 30px;
  margin-right: 12px;
  padding: 0;
  padding-left: 5px;
  text-align: center;
}

.company_with_logo{
  display:flex;
  align-items: center;
  gap: 8px;
  margin-top: 12px;
}

.company_with_logo h5{
  width: calc(100% - 34px);
}

.clogo{
  width: 34px;
  height: 34px;
  /* overflow: hidden; */
  display: flex;
  align-items: center;
  justify-content: center;
  
}

.clogo img{
  max-width: 34px;
  max-height: 34px; 
  border-radius: 50%;
}

.dashboard_row{
  display: flex;
  gap: 20px;
}

.dashboard_user_col{
  width: 29%;
  border-radius: 16px;
  background: #FFF;
  box-shadow: 0px 4px 20px 2px rgba(220, 220, 220, 0.25);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.dashboard_company_col{
  width: 22%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.dashboard_report_col{
  width: 49%;
  border-radius: 16px;
  background: #FFF;
  box-shadow: 0px 4px 20px 2px rgba(220, 220, 220, 0.25);
  padding: 24px;
}

.dashboard_user_row{
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 25px 20px;
  height: 60%;
}

.img_duser{
  width: 100px;
  height: 130px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.img_duser img{
  max-width: 100%;
  max-height: 100%;
  border:1px solid #E1E1E1;
  border-radius: 8px;
}

.title_main_dashboard{
  font-weight: 600;
  font-size: 34px;
  color: #141B3C;
}

.details_duser h5{
  color: #111;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.details_duser p{
  color: rgba(45, 45, 45, 0.50);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 10px;
}

.details_duser h6{
  color: #000;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 26px;
}

.details_duser h6 span{ 
  color: #696969;
}

.dashboard_user_activity{
  padding: 20px 0px;
  border-radius: 0px 0px 16px 16px;
  background:#FFF9EF;
  display: flex;
  justify-content: center;
  text-align: center;
  height: 40%;
  align-items: center;
}

.activity_number_col{
  width: 33.333%;
}

.activity_number_col h5{
  color: #2B3779;
  text-align: center;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.activity_number_col p{
  color: #2B3779;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 10px;
}

.total_companies_row{
  border-radius: 16px;
  background: #FFF;
  box-shadow: 0px 4px 20px 2px rgba(220, 220, 220, 0.25);
  padding: 16px 22px;
  height: 70%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.total_companies_row p{
  color: #4B4B4B;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.tc_number {
  color: #141B3C;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-left: 2px solid #FEE3B0;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 12px;
}

.dreport_review_row{
  border-radius: 16px;
  background: #FFF;
  box-shadow: 0px 4px 20px 2px rgba(220, 220, 220, 0.25);
  padding: 16px 22px;
  height: 100%;
  display: flex;
  justify-content:center;
  text-align: center;
  align-items: center;
}

.col_dreport_review{
  width: 50%;
}

.col_dreport_review h5{
  color: #3669D9;
  text-align: center;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.col_dreport_review p{
  color: #6B91E3;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  margin-top: 10px;
}

.dashboard_report_row{
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap:20px;
}

.dashboard_report_col h5{
  color:#2D2D2D;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  margin-bottom: 22px;
}

.dreport_analylsed{
  padding: 12px 16px;
  border-radius: 8px;
  background: #F2F5FC;
}


.dreport_analylsed p{
  color: #4B4B4B;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-bottom: 1px solid #A1B9ED;
  padding-bottom: 12px;
}

.dreport_analylsed h4{
  color: #2B3779;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 12px;
}

.dreport_analylsed h4 span{
  font-size: 18px;
  font-weight: 500;
}

.dashboard_report_col_row{
  display: flex;
  gap: 20px;
}

.daily_avrg_graph{
  border-radius: 10px;
  background: #FFF9EF;
  width: 65%;
  padding: 20px;
}

.daily_avrg_graph_row{
display: flex;
justify-content: space-between;
}

.graph_daily_areport p{
  color: #2E2E30;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.graph_daily_areport h5{
  color: #2E2E30;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 4px;
}

.graph_analyed_week p{
  color: #FCB633;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.graph_analyed_week h6{
  color: #2E2E30;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 4px;
}

.dreport__col{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width:35%
}

.dcompany_table{
  border-radius: 16px;
  background: #FFF;
  box-shadow: 0px 4px 20px 2px rgba(220, 220, 220, 0.25);
  padding: 22px;
  width: 51%;
}

.dcompany_table_title{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dcompany_table_title h5{
  color:#2D2D2D;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.dcompany_table_title p{
  color: #878787;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.dsystem_statics{
  border-radius: 16px;
  background: #F7FAFF;
  box-shadow: 0px 4px 20px 2px rgba(220, 220, 220, 0.25);
  padding: 22px;
  width: 48%;
}

.dsystem_statics h5{
  color: #2D2D2D;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.dsystem_statics p{
  color: #878787;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 10px;
}

.table_dashboard_company{
  border-radius: 16px;
  border: 1px solid #F5F5F5;
  box-shadow: none;
}

.table_dashboard_company th{
  background-color: #F2F5FC;
  color: #00203D;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.48px;
  padding:16px 20px;
  border:0;
}

.table_dashboard_company td{
  color: #000;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 8px 20px;
}

.table_dashboard_company .tag_td{
  font-size: 12px;
  text-transform: uppercase;
  width: 90px;
}

.row_dsystem_statics{
  display: flex;
  gap: 18px;
  flex-wrap: wrap;
}

.dsystem_statics_col{
  width: calc(50% - 9px);
  border-radius: 14px;
  border: 1px solid #F5F5F5;
  background: #FFF;
  padding: 18px;
}

.dsystem_statics_col h5{
  color: #0D163A;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; 
}

.dsystem_statics_col p{
  color: #0D163A;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: 8px;
}

.dsystem_statics_col h4{
  color: #0D163A;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-top: 1px solid #F0F1F1;
  margin-top: 12px;
  padding-top: 12px;
}

.comment_modal_body.slide-in{
  right: 0;
}

/* This is for sidebar selection */
.pro-sub-menu.open .pro-inner-list-item ul .pro-menu-item.active{
  background-color: rgba(255, 255, 255, 0.2);
  }
  
  .pro-sub-menu.open .pro-inner-list-item ul .pro-menu-item.active a{
    background:none !important;
    color: #FFF !important;
  }

  .extra_Pagination_gap{
    margin-right: 12px !important;
  }

  .modal_footer_comment_inner .form__control,
  .modal_footer_comment_inner .form__control:focus,
  .modal_footer_comment_inner .form__control:focus-visible{
    border:0 !important;
    box-shadow: none !important;
    border-radius: 4px !important;
    border:0;
    border-bottom: 2px solid #FDC152 !important;
    background: #F8F8F7 !important;
    display: block;
    padding: .375rem .75rem;
    outline: none;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    padding-top: 10px;
    color: var(--bs-body-color);
    width: 100%;
    min-height: 46px;
    height: 46px;
  }

  .textarea_resisable{
    resize: vertical;
  }

  .chart_row{
    padding: 10px 0; 
    border-radius: 10px;
    box-shadow: 0 0 10px #F0F0F0;
    background-color: #FFF;
    border:1px solid #F0F0F0;
    position: relative;
    height: 100%;
  }
  
  .chart_row h5{
    margin-bottom: 20px;
    padding-left: 25px;
    padding-top: 8px;
  }
  
  .message_main.rs-message:not(.rs-message-hiding)+.rs-message{
  margin-top: 0 !important;
  }
  
  .chart_tabs{
  padding: 0 20px;
  border-bottom: 0;
  margin-bottom: 10px;
  position: absolute;
  right: 0;
  top: 10px;
  }
  
  .chart_tabs .react-tabs__tab{
    border:1px solid #DDD;
    border-radius: 5px;
    font-size: 14px;
    margin-right: 8px;
    font-weight: 500;
    color: #606060;
    outline: none;
    padding: 5px 10px;
    display: inline-block;
    cursor: pointer;
  }
  
  .chart_tabs .react-tabs__tab--selected{
    border:1px solid #858bb1;
    background-color: #858bb1;
    color: #FFF;
  }
  
  .chart_tabs .react-tabs__tab:focus:after{
    display: none;
  }

  
  .radio_check_list{
    display: flex;
    flex-wrap: wrap;
  }

  .radio_check_list li{
    margin-right: 20px;
    margin-top: 10px;
  }

  .radio_check_list li:last-child{
    margin-right: 0px;
  }

  .hidden {
    display: none;
  }

  .radio {
    position: relative;
    cursor: pointer;
    line-height: 20px;
  }
  .radio .label {
    position: relative;
    display: block;
    float: left;
    margin-right: 10px;
    width: 20px;
    height: 20px;
    border: 2px solid #c8ccd4;
    border-radius: 100%;
    -webkit-tap-highlight-color: transparent;
  }
  .radio .label:after {
    content: '';
    position: absolute;
    top: 3px;
    left: 3px;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background: #4a63a9;
    transform: scale(0);
    transition: all 0.2s ease;
    opacity: 0.08;
    pointer-events: none;
  }
  .radio:hover .label:after {
    transform: scale(3);
  }
  input[type="radio"]:checked + .label {
    border-color: #4a63a9;
  }
  input[type="radio"]:checked + .label:after {
    transform: scale(1);
    transition: all 0.2s cubic-bezier(0.35, 0.9, 0.4, 0.9);
    opacity: 1;
  }

  .rs-popover[class*=placement-left]>.rs-popover-arrow:after{
    border-width: 12px 0 14px 14px;
    margin-top: -9px;
    right: -10px;
  }

  .chart_row{
    padding: 10px 0; 
    border-radius: 10px;
    box-shadow: 0 0 10px #F0F0F0;
    background-color: #FFF;
    border:1px solid #F0F0F0;
    position: relative;
    height: 100%;
  }
  
  .chart_row h5{
    margin-bottom: 20px;
    padding-left: 25px;
    padding-top: 8px;
  }
  
  .message_main.rs-message:not(.rs-message-hiding)+.rs-message{
  margin-top: 0 !important;
  }
  
  .chart_tabs{
  padding: 0 20px;
  border-bottom: 0;
  margin-bottom: 10px;
  position: absolute;
  right: 0;
  top: 10px;
  }
  
  .chart_tabs .react-tabs__tab{
    border:1px solid #DDD;
    border-radius: 5px;
    font-size: 14px;
    margin-right: 8px;
    font-weight: 500;
    color: #606060;
    outline: none;
    padding: 5px 10px;
    display: inline-block;
    cursor: pointer;
  }
  
  .chart_tabs .react-tabs__tab--selected{
    border:1px solid #858bb1;
    background-color: #858bb1;
    color: #FFF;
  }
  
  .chart_tabs .react-tabs__tab:focus:after{
    display: none;
  }

  
  .radio_check_list{
    display: flex;
    flex-wrap: wrap;
  }

  .radio_check_list li{
    margin-right: 20px;
    margin-top: 10px;
  }

  .radio_check_list li:last-child{
    margin-right: 0px;
  }

  .hidden {
    display: none;
  }

  .radio {
    position: relative;
    cursor: pointer;
    line-height: 20px;
  }
  .radio .label {
    position: relative;
    display: block;
    float: left;
    margin-right: 10px;
    width: 20px;
    height: 20px;
    border: 2px solid #c8ccd4;
    border-radius: 100%;
    -webkit-tap-highlight-color: transparent;
  }
  .radio .label:after {
    content: '';
    position: absolute;
    top: 3px;
    left: 3px;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background: #4a63a9;
    transform: scale(0);
    transition: all 0.2s ease;
    opacity: 0.08;
    pointer-events: none;
  }
  .radio:hover .label:after {
    transform: scale(3);
  }
  input[type="radio"]:checked + .label {
    border-color: #4a63a9;
  }
  input[type="radio"]:checked + .label:after {
    transform: scale(1);
    transition: all 0.2s cubic-bezier(0.35, 0.9, 0.4, 0.9);
    opacity: 1;
  }

  .rs-popover[class*=placement-left]>.rs-popover-arrow:after{
    border-width: 12px 0 14px 14px;
    margin-top: -9px;
    right: -10px;
  }

  .remove_Comment a{
    text-decoration: none !important;
  }

  .icon_comment_active{
    background-color: #FFF4CC !important;
    border-color: #fee1b9 !important;
  }

/*  */
.login_content{
  position: relative;
  z-index: 1
}

.login-container2 {
  height: 0;
  background: #FFF;
  position: relative;
  /* overflow: hidden; */
  /* transition: 0.3s cubic-bezier(.94,.21,.63,1.09) 0.3s; */
  z-index: 0;
}

.login-container2.effect-active{
  z-index: 999;
}

/* Radial effect styling */
.radial-effect {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 200px; /* Start with 0 size */
  height: 200px;
  background: #293364; /* Semi-transparent red overlay */
  border-radius: 50%;
  transform: translate(-50%, -50%);
  transition: 0.3s cubic-bezier(.94,.21,.63,1.09) 0.3s;
  z-index: 0;
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.radial-effect.show {
  width: 200vw; /* Cover the entire viewport */
  height: 200vh;
  border-radius: 50%;
  z-index: 999;
  opacity: 1;
  transition: 0.3s cubic-bezier(.94,.21,.63,1.09) 0.3s;
}


/* CSS */
.container {
  position: relative;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

.rippolEffect {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100vw;
  height: 100vh;
  z-index: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  clip-path: circle(100% at 50% 50%);
  transition: z-index 0.3s ease;
  opacity: 0;
}

.rippolEffect.active {
  z-index: 999 !important;
  opacity: 1;
}

.clsBold{
  font-weight: 600;
  color: #2D2D2D;
}

.revenueParentField{
  font-weight: 600;
  color: #2D2D2D;
}
.revenueChildField{
  color: #4B4B4B;
}

.rs-table-body-wheel-area .rs-table-row:nth-child(even) .rs-table-cell{
  background-color: #f8f8f8;
}

.MuiDataGrid-row--borderBottom{
  background-color: #FFF9EF !important;
}

.MuiDataGrid-columnHeaderTitleContainerContent .MuiDataGrid-columnHeaderTitle {
  font-weight: 600;
  color: #00203D;
  font-family: 'Raleway';
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.6px;
}

.MuiDataGrid-row:nth-child(even){
  background-color: #F8F8F8;
}

.Icon_action_edit{
  color: #37468b !important;
  background: none !important
}

.Icon_action_delete{
  color: #e9454e !important;
  background: none !important;
}

.Icon_action_edit svg,
.Icon_action_delete svg{
  width: 22px !important;
  height: 22px !important;
}

/* .clsDropdown__indicators .caret_green,
.clsDropdown__indicators .caret_red,
.clsDropdown__indicators .caret_orange{
  display: none !important;
} */

.clsDropdown__single-value > div{justify-content: flex-end;}

.header_cb_activity{
  border-radius: 8px 8px 0px 0px;
  background: #141B3C;
  display: flex;
  padding: 6px 12px;
  justify-content: space-between;
  align-items: center;
  color: #FFF;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  text-transform: uppercase;
}

.header_cb_activity_toggle{
  cursor: pointer;
}

.subheader_cb_activity{
  background: #787FA0;
  display: flex;
  padding: 5px 0px;
  justify-content: center;
  align-items: center;
  color: #FFF;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.fTable_comment_box{
  padding:12px 8px;
}

.textarea__control{
  color: #4B4B4B;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  border-radius: 4px;
  border: 0.4px solid #C3C3C3;
  outline: none;
  min-height: 40px;
  padding: 4px 12px 4px 8px;
  width: 100%;
  display: block;
}

.deletePopover{
  padding: 10px;
}

.deletePopover h5{
  font-size: 18px;
  font-weight: 600;
  color: #2D2D2D;
}

.deletePopover p{
  font-size: 16px;
  font-weight: 400;
  color: #4B4B4B;
  margin-top: 4px;
  padding: 0 15px;
}

.deletePopoverWrap.rs-popover.placement-left-end>.rs-popover-arrow, 
.deletePopoverWrap.rs-popover.placement-right-end>.rs-popover-arrow {
  bottom: 15px;
}

.deletePopover .btn__group{
  margin-top: 35px;
}

.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #f5c351;
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark {
  width: 52px;
  height: 52px;
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;
  display: block;
  stroke-width: 2;
  stroke: #f5c351 ;
  stroke-miterlimit: 10;
  box-shadow: inset 0px 0px 0px #f5c351;
  animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes scale {
  0%, 100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
@keyframes fill {
  100% {
    /*box-shadow: inset 0px 0px 0px 30px #0ecb8b;*/
  }
}

.thankyou_content h4{margin-top: 15px; font-size: 22px; font-weight: 600; color: #2D2D2D;}

.thankyou_content p{margin-top: 5px; font-size: 16px; color: #4B4B4B;}

.MuiTooltip-tooltip {
  background-color: rgba(213, 217, 253, 1) !important;
  border-radius: 4px;
  color: #000 !important;
  font-family: "Inter" !important;
  padding: 4px 8px;
  font-size: 12px !important;
  max-width: 300px;
  margin: 0;
  word-wrap: break-word;
  font-weight: 500;
  position: relative;
}

.css-kudwh-MuiTooltip-arrow{
  color: rgba(213, 217, 253, 1) !important;
}

.toggle_div .rs-toggle-presentation{background-color: #DA293E !important;}

.toggle_div .rs-toggle-checked .rs-toggle-presentation{
  background-color: #0A8F43 !important;
  position: relative;
}

.toggle_div .rs-toggle .rs-toggle-presentation:after {
  content: url(../images/IconCrossToggle.svg); /* CheckIcon SVG as base64 */
}

.toggle_div .rs-toggle-checked .rs-toggle-presentation:after {
  content: url(../images/IconArrowToggle.svg); /* CheckIcon SVG as base64 */
}

.toggle_div .rs-toggle-handle:before {
  content: "";
  display: inline-block;
  width: 100%;
  height: 100%;
}


.toggle_div .rs-toggle .rs-toggle-inner .rs-icon{
  display: none;
}

.roleTitleRow{
display: flex;
align-items: center;
justify-content: space-between;
gap: 15px;
}

.btn_userRoleDelete{
background-color: #F2F5FC;
width: 24px;
height: 24px;
border-radius: 50%;
border: 1px solid #BCCDF2;
display:flex;
align-items: center;
justify-content: center;
color: #DA293E;
}

.clsCheckingReport{
  text-align: left;
  display:flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 20px;
}
.clsCheckingReport p{
  font-weight: 600;
  color: #262626;
}

.clsCheckingReport p img{
  margin-left: 5px;
}

.clsCheckloader {
  animation: rotate 0.8s linear infinite; /* Rotate for 2 clsCalculator infinitely */
  width: 16px; /* Set your desired width */
  height: 16px; /* Set your desired height */
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


.collapse {
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.4s ease-in-out;
}

.collapse.show {
  max-height: 1000px; /* Set this to the expected maximum height */
}

.collapse.hide {
  max-height: 0;
}

 

.etf_holding_table .table tr.table_tr_odd td.highlightedGreen {
  background-color: #E1EBD9 !important;
} 
.etf_holding_table .table tr.table_tr_even td.highlightedGreen {
  background-color: #E1EBD9 !important;
} 

.etf_holding_table .table tr.table_tr_odd td.highlightedYellow {
  background-color: #FDF1C7 !important;
} 
.etf_holding_table .table tr.table_tr_even td.highlightedYellow {
  background-color: #FDF1C7 !important;
} 

.etf_holding_table .table tr.table_tr_odd td.highlightedRed {
  background-color: #FDCEAB !important;
} 
.etf_holding_table .table tr.table_tr_even td.highlightedRed {
  background-color: #FDCEAB !important;
} 

.th_sort:after{
  content: "icon";
  display: inline-block;
}

.th_sort:before{
  content: "icon";
  display: inline-block;

}


.assetBody{
  padding: 30px;
  padding-top: 10px;
  box-shadow: 0 0 4px #ddd;
  border-radius: 8px;
  margin-top: 12px;
  margin-right: 12px;
}

.assetBody h5{
  font-weight: 600;
  color: #111;
  font-size: 20px;
  text-align: center;
}

.assetField{
  margin-top: 20px;
}

.assetField {
  margin-top: 20px;
  
}

.etf_holding_table .table tr.table_tr_odd.highlightRow td
{
  background-color: #FFE5B7 !important;
}

.etf_holding_table .table tr.table_tr_even.highlightRow td
{
  background-color: #FFE5B7 !important;
}


.table_dropdown_button button {
  color: #00203D;
  font-family: 'Raleway';
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.6px;
  vertical-align: middle;
  white-space: nowrap;
  background: none;
  padding-left: 0;
}

.clsPercentageLabel{
  display :block;
  text-align: right;
}

.fxCurrencyComapnyProfile{
  width: fit-content;
}

/* Annual & Quarter Report Modal CSS */
.custom_backdrop .rs-modal-backdrop {
  background-color: transparent;
}

.annual_report_modal {
  max-width: 400px;
  position: absolute;
  right: 10px;
  top: 0;
  bottom: 0;
  display: flex !important;
  align-items: center;
  margin-bottom: 20px !important;
}

.annual_report_modal .rs-modal-header .rs-modal-header-close {
  font-size: 15px;
  top: 18px;
}

.annual_report_modal_header {
  border-radius: 12.77px 12.77px 0px 0px;
  background: #EAF9E8;
  height: 52px;
  display: flex;
  align-items: center;
  gap: 15px;
}

.quarterly_report_modal_header {
  border-radius: 12.77px 12.77px 0px 0px;
  background: #E6EEFF;
  height: 52px;
  display: flex;
  align-items: center;
  gap: 15px;
}

.annual_report_modal_header h4,
.quarterly_report_modal_header h4 {
  color: #2D2D2D;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.annual_report_modal .rs-modal-content {
  overflow: hidden;
  border-radius: 12px;
  background: #FFF;
  box-shadow: 0px 2px 24px 1px rgba(105, 105, 105, 0.25);
}

.annual_report_modal .rs-modal-body {
  max-height: 80vh !important;
}

.annual_report_modal_content {
  padding: 15px;
  padding-top: 0;
}

.title_steps {
  color: #000;
  font-size: 15px;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  text-transform: uppercase;
  border-bottom: 0.798px solid #CDF3C6;
  padding-bottom: 12px;
}

.form_field_row {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  margin-top: 15px;
}

.form_field_col {
  width: calc(50% - 5px);
}

.custom_field {
  border-radius: 3.193px;
  background: var(--Action-Grey-150, #F6F8FA);
  border: 0;
  height: 38px;

}

.form_field_col .label_form {
  color: #2D2D2D;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 5px;
}


.custom_field::-ms-input-placeholder {
  /* Edge 12-18 */
  color: var(--Action-Grey-800, #2D2D2D);
  font-feature-settings: 'liga' off, 'clig' off;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 19.156px;
}

.custom_field::placeholder {
  color: var(--Action-Grey-800, #2D2D2D);
  font-feature-settings: 'liga' off, 'clig' off;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 19.156px;
}

.form_pink_total_row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  display: flex;
  height: 38px;
  padding: 7px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 3.193px;
  background: #FFF9EF;
  color: #000;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  width: 100%;
  margin-top: 15px;
}

.form_pink_total_row h4 {
  color: #000;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.form_field_col_full {
  width: 100%;
}

.annual_report_select_drop {
  border-radius: 3.193px;
  background: var(--Action-Grey-150, #F6F8FA);
  border: 0;
  height: 38px;
  display: flex;
  align-items: center;
}

.annual_report_select_drop .rs-picker-toggle .rs-picker-toggle-placeholder {
  color: var(--Action-Grey-800, #2D2D2D);
  font-feature-settings: 'liga' off, 'clig' off;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
}

.modal_footer_annual_report {
  border-radius: 0;
  background: #FFF;
  box-shadow: none;
  display: flex;
  padding: 12px 20px;
  padding-top: 0;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.modal_footer_annual_report .btn__group {
  width: 100%;
}

.modal_footer_annual_report .btn_cancel,
.modal_footer_annual_report .btn_save_form {
  width: 50%;
}

.modal_footer_annual_report .btn_cancel {
  border-radius: 3.193px;
  border: 0.798px solid var(--Bright-Yellow-600, #FDC152);
  display: flex;
  height: 39.908px;
  padding: 3.193px 19.954px;
  justify-content: center;
  align-items: center;
  gap: 4.789px;
  flex: 1 0 0;
}

.modal_footer_annual_report .btn_save_form {
  display: flex;
  height: 39.908px;
  padding: 3.193px 19.954px;
  justify-content: center;
  align-items: center;
  gap: 4.789px;
  flex: 1 0 0;
  border-radius: 3.193px;
  background: var(--Bright-Yellow-400, #FDD791);
}

.table_dropdown_button button {
  color: #00203D;
  font-family: 'Raleway';
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.6px;
  vertical-align: middle;
  white-space: nowrap;
  background: none;
  padding-left: 0;
}

.clcbtnsgroup{
  display: flex;
  align-items: center;
  gap:8px; 
}

.clcbtnsgroup button{
  background: none;
  border: 0;
  padding: 0;

}

.clsCalculator{
  text-align: right;
  /* border: 1px solid #D92A3F; */
}

.page-item ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #888;
}
.page-item ::-moz-placeholder { /* Firefox 19+ */
  color: #888;
}
.page-item :-ms-input-placeholder { /* IE 10+ */
  color: #888;
}
.page-item :-moz-placeholder { /* Firefox 18- */
  color: #888;
}

.totalRecords{
  color: #888;
  font-weight: 600;
  font-size: 14px;
}

.clsPagingWrap{
  padding-bottom: 50px !important;
}


.rs-toggle-checked .rs-toggle-presentation,
.rs-toggle-checked .rs-toggle-presentation:hover {
  background-color: #37468b;
}

.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked, .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate{
  color: #37468b !important;
}

.action_btn_row{
  display: flex;
  align-items: center;
/*  gap: 10px;*/
}

.css-rekhlp-MuiDataGrid-root{
  font-family: 'Inter' !important;
}

.Icon_action_delete{
  padding: 0;
}

.clsPagingWrap th:last-child .table_dropdown_button.rs-dropdown-menu, 
.clsPagingWrap th:nth-child(15) .table_dropdown_button.rs-dropdown-menu {
  left: inherit !important;
  right:0;
}


.btn__group__row{
  display:flex;
  align-items: center;
  justify-content: space-between;
  gap:15px;
}

.btn__group__row button, .btn__group__row Button{
  width: 33.33%;
}

.clsDropdown__control--is-disabled{
background-color: #FFF !important;
border: 0 !important;
min-height: inherit !important;

}

.clsDropdown__control--is-disabled .clsDropdown__indicators{
display: none !important;
}

.btn_edit_icon{
  border: 0;
  background: none;
  font-size: 20px;
  color: #507CDD;
  vertical-align: middle;

}


/*MODAL*/
.edit_status_modal{
  display: flex !important;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.rs-modal.edit_status_modal{
  max-width: 700px;
  padding: 32px;
}

.edit_status_modal .rs-modal-dialog{
  width: 100%;
}

.edit_modal_content{
  padding-left: 32px;
  padding-right: 32px;
}

.edit_status_modal .rs-modal-content{
 border-radius: 16px;
background: #FFF;
box-shadow: 0px 2px 24px 1px rgba(105, 105, 105, 0.25);
}

.edit_modal_header{
border-radius: 16px 16px 0px 0px;
background: #F2F5FC;
padding: 14px 32px;
text-align: center;
}

.edit_modal_header h4{
  color: #161616;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.44px;
}

.edit_modal_header p{
  color: #696969;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.36px;
  margin-top: 6px;
}

.edit_status_field{
  color: #696969;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.edit_status_field label{
  margin-bottom: 5px;
}

.edit_modal_header .rs-modal-header-close{
  display: none !important;
}

.modal_footer_edit_status{
  background-color: #FFF;
  box-shadow: none;
  padding-bottom: 35px;
}

.select_edit_status{
  display: flex;
  width: 100%;
  height: 46px;
  padding: 8px 5px;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  background: #F5F5F5;
  border: 1px solid #E1E1E1;
}

.select_edit_status .rs-picker-toggle .rs-picker-toggle-placeholder{
  color: #4B4B4B;
  font-feature-settings: 'liga' off, 'clig' off;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}


.modal_footer_edit_status .btn_cancel{
border-radius: 4px;
border: 1px solid #FDC152;
width: 134px;
height: 44px;
padding: 3px 8px;
color: #141B3C;
font-size: 15px;
font-style: normal;
font-weight: 500;
line-height: normal;
}

.modal_footer_edit_status .btn___reset{
  border-radius: 4px;
  border: 1px solid var(--Bright-Yellow-600, #FDC152);
  background: var(--Action-Yellow-100, #FFF9E4);
  width: 134px;
  height: 44px;
  padding: 3px 8px;
  color: #141B3C;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.modal_footer_edit_status .btn__save{
border-radius: 4px;
background: var(--Bright-Yellow-400, #FDD791);
width: 134px;
height: 44px;
padding: 3px 8px;
color: #141B3C;
font-size: 15px;
font-style: normal;
font-weight: 600;
line-height: normal;
}


.btn_Back_head{
  background: none;
  border: 0;
  border-radius: 50%;
  width: 34px;
  height: 34px;
  padding: 4px;
}

.btn_Back_head img{
 max-width: 100%;
}

.popover__range__wrap{
  border-radius: 8px !important;
  background: #FFF;
  box-shadow: 0px 2px 24px 1px rgba(225, 225, 225, 0.80), 0px 2px 24px 1px rgba(225, 225, 225, 0.80) !important;
}

.popover__range__select{
  display: flex;
  align-items: center;
  gap: 6px;
}

.popover__range__select span{
  color: #414141;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.select__range__col{
  width: 100px;
  display: flex;
  width: 100px;
  padding: 1px 0px;
  justify-content: space-between;
  align-items: center;
}

.select__range__col input{
  width: 100%;
  border: 0;
  height: 30px;
  outline: none;
  padding: 1px 8px;
  box-shadow: none;
  color: #828282;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-radius: 4px;
  border: 1px solid #D1E1F1;
  background: #FFF;
}

.popover__range__wrap .rs-picker-toggle .rs-picker-toggle-placeholder{
  color: #828282;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.popover__range__wrap.rs-popover[class*=placement-bottom]>.rs-popover-arrow:after {
    border-bottom-color: #fff;
    border-bottom-color: var(--rs-bg-overlay);
    border-width: 0 10px 10px;
    margin-left: -9px;
    top: -8px;
}

.btn_recover{
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border: 1px solid #A1B9ED;
  background-color: transparent;
  color: #A1B9ED;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  margin: 0 !important;
}

.deleted_header{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 15px;
  padding-bottom: 15px;
}

.btn_remove_all{
  display: flex;
padding: 10px 16px;
justify-content: center;
align-items: center;
gap: 8px;
align-self: stretch;
border-radius: 4px;
border: 1px solid #DA293E;
background: #FFF2F2;
color: #DA293E;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: normal;
}

.btn_remove{
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border: 1px solid #A1B9ED;
  background-color: transparent;
  color: #DA293E;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  margin: 0 !important;

}

.action_row_recover {
  display: flex;
  align-items: center;
  gap:5px;
  justify-content: center;
}


.btn_Delete_Profile{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 12px;
  height: 48px;
  width: 160px;
  font-size: 18px;
  color: #FFF !important;
  text-align: center;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 8px;
  background: #141B3C !important;
}

.edit_status_field label{
 display: flex;
 align-items: center;
 gap: 10px;
}

.edit_status_field label img{
  cursor: pointer;
 }


 
/* Upload File */

.upload_section_main .rs-uploader-draggable .rs-uploader-trigger-customize .rs-uploader-trigger-btn {
  border: 0;
  background: none;
}

.upload_section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px;
  gap: 16px;
  text-align: center;
  border-radius: 8px;
  border: 1px dashed #A1B9ED;
  background: #FFF;
  transition: all 0.4s;
}

.upload_section:hover {
  background: #F2F5FC;
}

.upload_section p {
  color: #4B4B4B;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.64px;
}

.upload_section .or__text {
  color: #6D6D6D;
  text-align: center;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.64px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.upload_section .or__text span {
  display: inline-block;
  padding: 3px 12px;
  position: absolute;
  background-color: #FFF;
}

.upload_section:hover .or__text span {
  background-color: #F2F5FC;
}

.upload_section .or__text::before {
  height: 1px;
  width: 200px;
  margin: auto;
  content: "";
  display: flex;
  background-color: #E7E7E7;
}

.upload_section img {
  height: 60px;
}

.btn__browse__file {
  display: flex;
  padding: 8px 24px;
  align-items: flex-start;
  gap: 4px;
  border-radius: 2px;
  border: 1px solid #A1B9ED;
  background: #F2F5FC;
  color: #212529;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  /* 100% */
  letter-spacing: 0.36px;
}

.upload_section_main h6 {
  overflow: hidden;
  color: #6D6D6D;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.64px;
  margin-top: 10px;
}

.rs-uploader-text .rs-uploader-file-item {
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid #E7E7E7;
  background: #FFF;
}

.upload_section_main .rs-btn-close .rs-icon {
  display: none;
}

.upload_section_main .rs-uploader-file-item-btn-remove.rs-btn-close {
  background: url('../images/iconCancel.svg') no-repeat center center;
  background-size: contain;
  width: 20px;
  height: 20px;
  border: none;
  padding: 0;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
}

.upload_section_main .rs-uploader-picture-text .rs-uploader-file-item-progress {
  border-radius: 40px;
  background: #F5F5F5;
  height: 8px;
  width: calc(100% - 30px);
  padding-left: 0;
  right: 0;
  margin: auto;
  display: none;
}

.upload_section_main .rs-uploader-picture-text .rs-uploader-file-item-preview>img {
  max-height: 36px;
  object-fit: contain;
}

.upload_section_main .rs-uploader-picture-text .rs-uploader-file-item-progress-bar {
  background-color: #2B3779;
  border-radius: 40px;
  height: 8px;
}

.upload_section_main .rs-uploader-picture-text .rs-uploader-file-item {
  border-radius: 8px;
  border: 1px solid #E7E7E7;
  background: #FFF;
  padding: 3px 15px;
  height: auto;
  display: flex;
  align-items: center;
}

.upload_section_main .rs-uploader-picture-text .rs-uploader-file-item-preview {
  position: static;
  display: flex;
  align-items: center;
  background: none;
  border-radius: 0;
  height: 40px;
}

.upload_file_modals .modal-header {
  text-align: center;
  flex-direction: column;
  border-radius: 16px 16px 0px 0px;
  background: var(--Faint-Navy-100, #F2F5FC);
}

.upload_file_modals .modal-title {
  color: #161616;
  font-size: 27px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.56px;
  text-align: center;
}

.upload_file_modals .modal-header p {
  color: #696969;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.36px;
  margin-top: 7px;
}

.upload_file_modals .modal-body {
  padding: 50px 24px
}

.upload_progress {
  background-color: #FFF;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
}

.upload_file_modals .rs-uploader-file-item-icon-wrapper.rs-uploader-file-item-icon-loading {
  display: none
}

.upload_file_modals .btn_cancel {
  padding: 4px 25px;
  color: #4B4B4B;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.36px;
  border-radius: 4px;
  border: 1px solid #FDC152;
  height: 48px;
  max-width: 200px;
  width: 100%;
}

.upload_file_modals .btn__next {
  color: #656565;
  padding: 4px 25px;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.36px;
  border-radius: 4px;
  background: #FEE3B0;
  height: 48px;
  max-width: 200px;
  width: 100%;
}

.upload_file_modals .rs-uploader-picture-text .rs-uploader-file-item-content .rs-uploader-file-item-title {
  color: #2D2D2D;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.32px;
}

.upload_file_modals .rs-uploader-picture-text .rs-uploader-file-item-content .rs-uploader-file-item-size {
  color: #6D6D6D;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.48px;
}

.upload_viewer {
  position: absolute;
  left: 15px;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 50px;
  height: 36px;
}

.custom_progress_row {
  width: 98%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  padding-top: 10px;
  background-color: #FFF;
}

.custom_progress_row h5 {
  color: #2D2D2D;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  /* 112.5% */
  letter-spacing: 0px;
}

.custom_progress_row p {
  color: #6D6D6D;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  /* 133.333% */
  letter-spacing: 0px;
}

.btn__upload__local__file {
  display: flex;
  height: 36px;
  padding: 3px 16px;
  align-items: center;
  gap: 44px;
  border-radius: 4px;
  background: #040031;
  color: #FFF;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.annual_report_modal.slide-in {
  right: inherit;
  left: 10px;
}